import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const provider = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_BY_ID_REQUEST:
    case at.FETCH_BY_ID_FAILURE: {
      return defaultState;
    }
    case at.FETCH_BY_ID_SUCCESS: {
      let { provider } = action;
      return provider;
    }
    case at.UPDATE_APPROVALS_SUCCESS: {
      const { data } = action.result;
      const modifiedApprovals = action.data;
      let newApprovals = state.approvals;

      if (modifiedApprovals.removedItems.length > 0) {
        modifiedApprovals.removedItems.forEach(element => {
          const result = newApprovals.filter(app => app.id === element.id);
          if (result.length > 0) {
            var index = newApprovals.indexOf(result[0]);
            if (index > -1) {
              newApprovals.splice(index, 1);
            }
          }
        });
      }

      //just when added
      if (data) {
        let newData = data.map((item) => {
          return Object.assign({}, item, {
            name: item.approvalName,
          });
        });

        newApprovals = newApprovals.concat(newData);
      }

      state.approvals = newApprovals;
      return state;
    }
    case at.SAVE_REQUEST: {
      return state;
    }
    case at.SAVE_SUCCESS: {
      let { data } = action.data;
      const newState = {
        ...state,
        name: data.name,
        homePage: data.homePage,
        biography: data.biography,
        facebookUserName: data.facebookUserName,
        twitterUserName: data.twitterUserName,
        linkedinUserName: data.linkedinUserName,
        googlePlusUserName: data.googlePlusUserName,
        webSiteUrl: data.webSiteUrl
      };

      return newState;
    }
    case at.SAVE_LOGO_SUCCESS: {
      state.logoUrl = action.logoUrl;
      return state;
    }
    case at.SAVE_LOGO_FAILURE: {
      return state;
    }
    case at.REFRESH_LOGO: {
      return { ...state, logoUrl: state.logoUrl ? state.logoUrl + '?' + new Date().getTime() : '' };
    }
    case at.SAVE_BANNER_SUCCESS: {
      return { ...state, bannerImageUrl: action.bannerImageUrl };
    }
    case at.SAVE_BANNER_FAILURE: {
      return state;
    }
    case at.REFRESH_BANNER: {
      return { ...state, bannerImageUrl: state.bannerImageUrl + '?' + new Date().getTime() };
    }
    case at.UPDATE_DISCOUNT_SUCCESS: {
      const { provider } = action;
      state.hasDiscountProgram = provider.hasDiscountProgram;
      return state;
    }
    case at.UPDATE_DISCOUNT_REQUEST:
    case at.UPDATE_DISCOUNT_FAILURE: {
      return state;
    }
    case at.UPDATE_STRIPE_ACCOUNT_SUCCESS: {
      const { stripeAccountResult } = action;
      state = { ...state, stripeAccountId: stripeAccountResult.accountId }
      return state;
    }
    case at.REMOVE_STRIPE_ACCOUNT_SUCCESS: {
      state = { ...state, stripeAccountId: '' };
      return state;
    }
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_SUCCESS: {
      const { provider } = action;
      return { ...state, receiveCompletedCourseNotifications: provider.receiveCompletedCourseNotifications };
    }
    case at.SAVE_COURSE_STARTED_NOTIFICATION_SUCCESS: {
      const { provider } = action;
      return { ...state, receiveStartedCourseNotifications: provider.receiveStartedCourseNotifications };
    }
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST:
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_FAILURE: {
      return state;
    }
    case at.TOGGLE_CEBROKER_INTEGRATION_SUCCESS: {
      const { data } = action;
      return { ...state, isEnabledCEBIntegration: data.isEnabledCEBIntegration };
    }
    case at.REFRESH_CEB_INTEGRATION_STATUS: {
      const { data } = action;
      return { ...state, isEnabledCEBIntegration: data.isEnabledCEBIntegration };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST:
    case at.FETCH_BY_ID_REQUEST: {
      return 'loading';
    }
    case at.FETCH_SUCCESS:
    case at.FETCH_BY_ID_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_FAILURE:
    case at.FETCH_BY_ID_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const saveProviderStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_REQUEST:
      return 'saving'
    case at.SAVE_FAILURE: {
      return 'failed';
    }
    case at.SAVE_SUCCESS: {
      return 'saved';
    }
    case at.CLEAR_SAVE_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const saveLogoStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_LOGO_REQUEST: {
      return 'saving';
    }
    case at.SAVE_LOGO_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_LOGO_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_LOGO_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const saveBannerStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_BANNER_REQUEST: {
      return 'saving';
    }
    case at.SAVE_BANNER_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_BANNER_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_BANNER_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const updateApprovalsStatus = (state = 'notCreated', action) => {
  switch (action.type) {
    case at.UPDATE_APPROVALS_REQUEST:
      return 'creating'
    case at.UPDATE_APPROVALS_SUCCESS: {
      return 'created';
    }
    case at.UPDATE_APPROVALS_FAILURE: {
      return 'failed'
    }
    case at.CLEAR_APPROVALS_STATUS: {
      return 'notCreated';
    }
    default:
      return state;
  }
};
export const toggleApprovalsInUse = (state = false, action) => {
  switch (action.type) {
    case at.TOGGLE_APPROVALS_IN_USE:
      return true;
    case at.CLEAR_TOGGLE_APPROVALS_IN_USE: {
      return false;
    }
    default:
      return state;
  }
};


export const updateDiscountStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_DISCOUNT_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_DISCOUNT_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_DISCOUNT_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const saveCourseCompleteNotificationStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST: {
      return 'saving';
    }
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_COURSE_COMPLETE_NOTIFICATION_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const saveCourseStartedNotificationStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_COURSE_STARTED_NOTIFICATION_REQUEST: {
      return 'saving';
    }
    case at.SAVE_COURSE_STARTED_NOTIFICATION_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_COURSE_STARTED_NOTIFICATION_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_COURSE_STARTED_NOTIFICATION_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const removeStripeAccountStatus = (state = 'notRemoved', action) => {
  switch (action.type) {
    case at.REMOVE_STRIPE_ACCOUNT_REQUEST: {
      return 'removing';
    }
    case at.REMOVE_STRIPE_ACCOUNT_SUCCESS: {
      return 'removed';
    }
    case at.REMOVE_STRIPE_ACCOUNT_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const toggleCEBrokerIntegrationStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.TOGGLE_CEBROKER_INTEGRATION_REQUEST: {
      return 'updating';
    }
    case at.TOGGLE_CEBROKER_INTEGRATION_SUCCESS: {
      return 'updated';
    }
    case at.TOGGLE_CEBROKER_INTEGRATION_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};


export const errorStatus = (state = defaultState, action, request) => {
  switch (action.type) {
    case at.FETCH_REQUEST:
    case at.SAVE_REQUEST:
    case at.SAVE_LOGO_REQUEST:
    case at.SAVE_BANNER_REQUEST:
    case at.UPDATE_APPROVALS_REQUEST:
    case at.UPDATE_DISCOUNT_REQUEST:
    case at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST:
    case at.SAVE_COURSE_STARTED_NOTIFICATION_REQUEST:
    case at.TOGGLE_CEBROKER_INTEGRATION_REQUEST:
    case at.REMOVE_STRIPE_ACCOUNT_REQUEST: {
      return [];
    }
    case at.REQUEST_FAILURE: {
      return action;
    }
    default:
      return state;
  }
};

export default combineReducers({ provider, fetchStatus, saveProviderStatus, errorStatus, updateApprovalsStatus, toggleApprovalsInUse, saveLogoStatus, saveBannerStatus, updateDiscountStatus, removeStripeAccountStatus, saveCourseCompleteNotificationStatus, toggleCEBrokerIntegrationStatus, saveCourseStartedNotificationStatus });
