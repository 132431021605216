
import at from './types';

export const updateFeatureCourse = function (data) {
    return { type: at.UPDATE_FEATURE_REQUEST, data };
};

export const updatePublishCourse = function (data) {
    return { type: at.UPDATE_PUBLISH_REQUEST, data };
};

export const createCourse = function (data) {
    return { type: at.CREATE_COURSE_REQUEST, data };
};

export const updateCourse = function (data) {
    return { type: at.UPDATE_REQUEST, data };
};

export const updateCebCodeCourse = function (data) {
    return { type: at.UPDATE_CEBCODE_REQUEST, data };
};

export const clearCebCodeCourseStatus = function (data) {
    return { type: at.CLEAR_CEBCODE_STATUS };
};

export const clearUpdateCourseStatus = function (data) {
    return { type: at.CLEAR_UPDATE_STATUS };
};

export const clearCreateCourseStatus = function (data) {
    return { type: at.CLEAR_CREATE_STATUS };
};

export const clearCebCodeCourses = function (data) {
    return { type: at.CLEAN_CEBCODE_COURSES_REQUEST, data };
};

export const fetch = function (data) {
    return { type: at.FETCH_REQUEST, data };
};

export const fetchCourse = function (data) {
    return { type: at.FETCH_COURSE_REQUEST, data };
};

export const updatePublishCourseBuilder = function (data) {
    return { type: at.UPDATE_PUBLISH_COURSE_BUILDER_REQUEST, data };
};

export const clearPublishCourseBuilderStatus = function (data) {
    return { type: at.CLEAR_PUBLISH_COURSE_BUILDER_STATUS };
};

export const clearPublishCourseBuilderErrors = function (data) {
    return { type: at.CLEAR_PUBLISH_COURSE_BUILDER_ERRORS };
};

export const updateCebCodeIndividualCourse = function (data) {
    return { type: at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST, data };
};

export const clearUpdateCebCodeIndividualCourseStatus = function (data) {
    return { type: at.CLEAR_UPDATE_CEBCODE_INDIVIDUAL_COURSE_STATUS, data };
};

export const refreshCourseImage = function () {
    return { type: at.REFRESH_COURSE_IMAGE };
};
