import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import ComponentLoader from '../ComponentLoader';

const LoadComponent = (opts) => {
    return Loadable(Object.assign({
        loading: ComponentLoader
    }, opts));
};

const Dashboard = LoadComponent({ loader: () => import('../../containers/Dashboard') });
const Courses = LoadComponent({ loader: () => import('../../containers/Courses') });
const Transactions = LoadComponent({ loader: () => import('../../containers/Transactions') });
const StudentsActivity = LoadComponent({ loader: () => import('../../containers/StudentsActivity') });
const CouponDetail = LoadComponent({ loader: () => import('../../containers/CouponDetail') });
const TransactionDetail = LoadComponent({ loader: () => import('../../containers/TransactionDetail') });
const EditProfile = LoadComponent({ loader: () => import('../../containers/EditProfile') });
const AccountSettings = LoadComponent({ loader: () => import('../../containers/AccountSettings') });
const CEBIntegration = LoadComponent({ loader: () => import('../../containers/CEBIntegration') });
const CourseBuilder = LoadComponent({ loader: () => import('../../containers/CourseBuilder') });
const CourseSettings = LoadComponent({ loader: () => import('../../containers/CourseSettings') });
const CertificateConfig = LoadComponent({ loader: () => import('../../containers/CertificateConfig') });
const Results = LoadComponent({ loader: () => import('../../containers/Results') });
const SurveyDetails = LoadComponent({ loader: () => import('../../containers/SurveyDetails') });
const TestDetails = LoadComponent({ loader: () => import('../../containers/TestDetails') });
const IndividualDetails = LoadComponent({ loader: () => import('../../components/CourseBuilder/Results/IndividualDetails') });
const AssociationsMembers = LoadComponent({ loader: () => import('../../containers/AssociationsMembers') });
const MemberDetail = LoadComponent({ loader: () => import('../../containers/MemberDetail') });
const ProviderUsers = LoadComponent({ loader: () => import('../../containers/ProviderUsers') });
const ProviderUserDetail = LoadComponent({ loader: () => import('../../containers/ProviderUserDetail') });
const Forbidden = LoadComponent({ loader: () => import('./Forbidden') });

const PrivateRoute = ({ component: Component, isEnabledAccess, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                isEnabledAccess
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: '/forbidden',
                    }} />
            )}
        />
    );
};

const Main = ({ user }) => {
    return (
        <main>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/courses" component={Courses} />
                <Route exact path="/transactions/history" component={Transactions} />
                <Route exact path="/transactions/coupons" component={Transactions} />
                <Route exact path='/transactions/coupons/:id' component={CouponDetail} />
                <Route exact path='/transactions/students/:id' component={TransactionDetail} />
                <Route path="/activity" component={StudentsActivity} />
                <PrivateRoute path="/account/profile" component={EditProfile} isEnabledAccess={!user.isAssociationsCourseEditor} />
                <Route exact path="/account/settings/general" component={AccountSettings} />
                <Route exact path="/account/settings/billing" component={AccountSettings} />
                <Route exact path="/account/settings/notifications" component={AccountSettings} />
                <PrivateRoute exact path="/account/settings/integrations" component={AccountSettings} isEnabledAccess={!user.isAssociationsCourseEditor} />
                <PrivateRoute exact path="/account/settings/integrations/CEBIntegration" component={CEBIntegration} isEnabledAccess={!user.isAssociationsCourseEditor} />
                <Route exact path="/course/edit/:id" component={CourseBuilder} />
                <Route exact path="/course/edit/:id/settings" component={CourseSettings} />
                <Route exact path="/course/edit/:id/settings/certificateConfig/:certificateConfigId" component={CertificateConfig} />
                <Route exact path="/course/edit/:id/results" component={Results} />
                <Route exact path="/course/edit/:id/results/survey/:surveyId/:selectedTab?" component={SurveyDetails} />
                <Route exact path="/course/edit/:id/results/test/:testId/:selectedTab?" component={TestDetails} />
                <Route exact path="/course/edit/:id/results/:itemType/:itemId/individuals/:licenseeId" component={IndividualDetails} />
                <Route exact path="/forbidden" component={Forbidden} />
                <PrivateRoute exact path="/members" component={AssociationsMembers} isEnabledAccess={user.isAssociations} />,
                <PrivateRoute exact path="/members/:id" component={MemberDetail} isEnabledAccess={user.isAssociations} />,
                <PrivateRoute exact path="/users" component={ProviderUsers} isEnabledAccess={user.isAssociations} />
                <PrivateRoute exact path="/users/:id" component={ProviderUserDetail} isEnabledAccess={user.isAssociations} />
            </Switch>
        </main>
    );
};

export default Main;
