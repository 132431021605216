export default function (globalState) {
    const state = globalState.providerUsers;

    const getProviderUsers = () => state.providerUsers;
    const getProviderUser = () => state.providerUser;
    const getFetchStatus = () => state.fetchStatus;
    const getFetchByIdStatus = () => state.fetchByIdStatus;
    const getFetchNextStatus = () => state.fetchStatusNext;
    const getFetchPrevStatus = () => state.fetchStatusPrev;
    const getRemoveStatus = () => state.removeStatus;
    const getShowRemovingModalStatus = () => state.showRemovingModalStatus;
    const getCreateStatus = () => state.createStatus;
    const getUpdateBasicInfoStatus = () => state.updateBasicInfoStatus;
    const getUpdateRoleStatus = () => state.updateRoleStatus;
    const getErrors = () => state.errorStatus;

    return {
        getProviderUsers,
        getProviderUser,
        getFetchStatus,
        getFetchByIdStatus,
        getFetchNextStatus,
        getFetchPrevStatus,
        getRemoveStatus,
        getShowRemovingModalStatus,
        getCreateStatus,
        getUpdateBasicInfoStatus,
        getUpdateRoleStatus,
        getErrors
    };
}
