export default function (globalState) {
    const state = globalState.students;

    const getStudents = () => state.students;
    const getFetchStatus = () => state.fetchStatus;
    const getErrors = () => state.errorStatus;

    return {
        getStudents,
        getFetchStatus,
        getErrors
    };
}
