import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/surveys';
import at from '../actions/types';

export default function* fetchByCourse(data) {
  try {
    const result = yield call(api.fetchSurveys, data);
    yield put({ type: at.FETCH_BY_COURSE_SUCCESS, surveys: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_BY_COURSE_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_BY_COURSE_REQUEST
    });
  }
}
