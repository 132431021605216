export default {
  FETCH_REQUEST: 'providers/FETCH_REQUEST',
  FETCH_SUCCESS: 'providers/FETCH_SUCCESS',
  FETCH_FAILURE: 'providers/FETCH_FAILURE',
  FETCH_BY_ID_REQUEST: 'providers/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS: 'providers/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE: 'providers/FETCH_BY_ID_FAILURE',
  SAVE_REQUEST: 'providers/SAVE_REQUEST',
  SAVE_SUCCESS: 'providers/SAVE_SUCCESS',
  SAVE_FAILURE: 'providers/SAVE_FAILURE',
  UPDATE_APPROVALS_REQUEST: 'providers/UPDATE_APPROVALS_REQUEST',
  UPDATE_APPROVALS_SUCCESS: 'providers/UPDATE_APPROVALS_SUCCESS',
  UPDATE_APPROVALS_FAILURE: 'providers/UPDATE_APPROVALS_FAILURE',

  TOGGLE_APPROVALS_IN_USE: 'providers/TOGGLE_APPROVALS_IN_USE',
  CLEAR_TOGGLE_APPROVALS_IN_USE: 'providers/CLEAR_TOGGLE_APPROVALS_IN_USE',

  SAVE_LOGO_REQUEST: 'provider/SAVE_LOGO_REQUEST',
  SAVE_LOGO_SUCCESS: 'provider/SAVE_LOGO_SUCCESS',
  SAVE_LOGO_FAILURE: 'provider/SAVE_LOGO_FAILURE',
  REFRESH_LOGO: 'provider/REFRESH_LOGO',
  SAVE_BANNER_REQUEST: 'provider/SAVE_BANNER_REQUEST',
  SAVE_BANNER_SUCCESS: 'provider/SAVE_BANNER_SUCCESS',
  SAVE_BANNER_FAILURE: 'provider/SAVE_BANNER_FAILURE',
  REFRESH_BANNER: 'provider/REFRESH_BANNER',

  REFRESH_CEB_INTEGRATION_STATUS: 'providers/REFRESH_CEB_INTEGRATION_STATUS',

  CLEAR_SAVE_STATUS: 'providers/CLEAR_SAVE_STATUS',
  CLEAR_APPROVALS_STATUS: 'providers/CLEAR_APPROVALS_STATUS',
  CLEAR_SAVE_LOGO_STATUS: 'providers/CLEAR_SAVE_LOGO_STATUS',
  CLEAR_SAVE_BANNER_STATUS: 'providers/CLEAR_SAVE_BANNER_STATUS',
  CLEAR_SAVE_COURSE_COMPLETE_NOTIFICATION_STATUS: 'providers/CLEAR_SAVE_COURSE_COMPLETE_NOTIFICATION_STATUS',
  CLEAR_SAVE_COURSE_STARTED_NOTIFICATION_STATUS: 'providers/CLEAR_SAVE_COURSE_STARTED_NOTIFICATION_STATUS',

  UPDATE_DISCOUNT_REQUEST: 'providers/UPDATE_DISCOUNT_REQUEST',
  UPDATE_DISCOUNT_SUCCESS: 'providers/UPDATE_DISCOUNT_SUCCESS',
  UPDATE_DISCOUNT_FAILURE: 'providers/UPDATE_DISCOUNT_FAILURE',

  UPDATE_STRIPE_ACCOUNT_SUCCESS: 'providers/UPDATE_STRIPE_ACCOUNT_SUCCESS',

  REMOVE_STRIPE_ACCOUNT_REQUEST: 'providers/REMOVE_STRIPE_ACCOUNT_REQUEST',
  REMOVE_STRIPE_ACCOUNT_SUCCESS: 'providers/REMOVE_STRIPE_ACCOUNT_SUCCESS',
  REMOVE_STRIPE_ACCOUNT_FAILURE: 'providers/REMOVE_STRIPE_ACCOUNT_FAILURE',

  SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST: 'providers/SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST',
  SAVE_COURSE_COMPLETE_NOTIFICATION_SUCCESS: 'providers/SAVE_COURSE_COMPLETE_NOTIFICATION_SUCCESS',
  SAVE_COURSE_COMPLETE_NOTIFICATION_FAILURE: 'providers/SAVE_COURSE_COMPLETE_NOTIFICATION_FAILURE',

  SAVE_COURSE_STARTED_NOTIFICATION_REQUEST: 'providers/SAVE_COURSE_STARTED_NOTIFICATION_REQUEST',
  SAVE_COURSE_STARTED_NOTIFICATION_SUCCESS: 'providers/SAVE_COURSE_STARTED_NOTIFICATION_SUCCESS',
  SAVE_COURSE_STARTED_NOTIFICATION_FAILURE: 'providers/SAVE_COURSE_STARTED_NOTIFICATION_FAILURE',

  TOGGLE_CEBROKER_INTEGRATION_REQUEST: 'providers/TOGGLE_CEBROKER_INTEGRATION_REQUEST',
  TOGGLE_CEBROKER_INTEGRATION_SUCCESS: 'providers/TOGGLE_CEBROKER_INTEGRATION_SUCCESS',
  TOGGLE_CEBROKER_INTEGRATION_FAILURE: 'providers/TOGGLE_CEBROKER_INTEGRATION_FAILURE',

  REQUEST_FAILURE: 'providers/REQUEST_FAILURE'
};
