export default {
    FETCH_REQUEST: 'bankAccount/FETCH_REQUEST',
    FETCH_SUCCESS: 'bankAccount/FETCH_SUCCESS',
    FETCH_FAILURE: 'bankAccount/FETCH_FAILURE',

    CREATE_REQUEST: 'bankAccount/CREATE_REQUEST',
    CREATE_SUCCESS: 'bankAccount/CREATE_SUCCESS',
    CREATE_FAILURE: 'bankAccount/CREATE_FAILURE',
    CLEAR_CREATE_STATUS: 'bankAccount/CLEAR_CREATE_STATUS',

    UPDATE_REQUEST: 'bankAccount/UPDATE_REQUEST',
    UPDATE_SUCCESS: 'bankAccount/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'bankAccount/UPDATE_FAILURE',

    REQUEST_FAILURE: 'bankAccount/REQUEST_FAILURE',
};
