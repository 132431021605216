import axios from 'axios';
import config from '../config';

export const reportToCEBroker = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/cebrosterattendees',
        method: 'POST',
        params: data,
        withCredentials: true
    });
};

export const updateLicenseNumber = ({ id, data }) => {
    return axios.request({
        url: config.API_URL + 'api/studentlicenses?id=' + id,
        method: 'PATCH',
        //headers: { 'X-HTTP-Method-Override': 'PATCH' },
        data: data,
        withCredentials: true
    });
};

export const fetchByStudentId = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/studentlicenses',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};