import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const revenue = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_YEAR_FAILURE:
    case at.FETCH_MONTH_FAILURE: {
      return defaultState;
    }
    case at.FETCH_YEAR_SUCCESS:
    case at.FETCH_MONTH_SUCCESS: {
      return action.revenue;
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_YEAR_REQUEST:
    case at.FETCH_MONTH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_YEAR_SUCCESS:
    case at.FETCH_MONTH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_YEAR_FAILURE:
    case at.FETCH_MONTH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const errorStatus = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_YEAR_REQUEST:
    case at.FETCH_MONTH_REQUEST: {
      return [];
    }
    case at.REQUEST_FAILURE: {
      return action;
    }
    default:
      return state;
  }
};

export default combineReducers({ revenue, fetchStatus, errorStatus });
