import { takeLatest } from 'redux-saga/effects';

import at from '../actions/types';
import fetch from './fetch';
import { updateBasicInfo, convertUserToAssociations } from './update';

export default function* root() {
  yield takeLatest(at.FETCH_REQUEST, fetch);
  yield takeLatest(at.UPDATE_REQUEST, updateBasicInfo);
  yield takeLatest(at.CONVERT_USER_ASSOCIATIONS_REQUEST, convertUserToAssociations);
}
