export default function (globalState) {
    const state = globalState.tests;

    const getTests = () => state.tests;
    const getFetchStatus = () => state.fetchStatus;
    const getErrors = () => state.errorStatus;

    return {
        getTests,
        getFetchStatus,
        getErrors
    };
}
