import at from './types';

export const fetchByAssociation = function (data) {
    return { type: at.FETCH_BY_ASSOCIATION_REQUEST, data };
};

export const postMemberInvites = function (data) {
    return { type: at.POST_MEMBER_INVITES_REQUEST, data };
};

export const clearPostMemberStatus = function () {
    return { type: at.CLEAR_POST_MEMBER_STATUS };
};

export const cancelInvite = function (data) {
    return { type: at.CANCEL_INVITE_REQUEST, data };
};

export const clearCancelStatus = function () {
    return { type: at.CLEAR_CANCEL_STATUS };
};

export const resendInvite = function (data) {
    return { type: at.RESEND_INVITE_REQUEST, data };
};

export const clearResendStatus = function () {
    return { type: at.CLEAR_RESEND_STATUS };
};

export const fetchById = function (data) {
    return { type: at.FETCH_MEMBER_BY_ID_REQUEST, data };
};

export const remove = function (data) {
    return { type: at.REMOVE_REQUEST, data };
};

export const clearRemoveStatus = function () {
    return { type: at.CLEAR_REMOVE_STATUS };
};

export const toggleRemoveModal = function (showModal) {
    return { type: at.TOGGLE_REMOVE_MODAL, showModal };
};
