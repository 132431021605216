import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/revenue';
import at from '../actions/types';

export default function* fetchByYear(data) {
  try {
    const result = yield call(api.fetchRevenueByYear, data);
    yield put({ type: at.FETCH_YEAR_SUCCESS, revenue: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_YEAR_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_YEAR_REQUEST
    });
  }
}
