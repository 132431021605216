import { createAction } from '../../../helpers/utils';

export default {
    ...createAction('courses', 'UPDATE_FEATURE'),
    ...createAction('courses', 'UPDATE_PUBLISH'),
    ...createAction('courses', 'CREATE_COURSE'),

    FETCH_REQUEST: 'courses/FETCH_REQUEST',
    FETCH_SUCCESS: 'courses/FETCH_SUCCESS',
    FETCH_FAILURE: 'courses/FETCH_FAILURE',

    UPDATE_REQUEST: 'courses/UPDATE_REQUEST',
    UPDATE_SUCCESS: 'courses/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'courses/UPDATE_FAILURE',

    UPDATE_CEBCODE_REQUEST: 'courses/UPDATE_CEBCODE_REQUEST',
    UPDATE_CEBCODE_SUCCESS: 'courses/UPDATE_CEBCODE_SUCCESS',
    UPDATE_CEBCODE_FAILURE: 'courses/UPDATE_CEBCODE_FAILURE',

    UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST: 'courses/UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST',
    UPDATE_CEBCODE_INDIVIDUAL_COURSE_SUCCESS: 'courses/UPDATE_CEBCODE_INDIVIDUAL_COURSE_SUCCESS',
    UPDATE_CEBCODE_INDIVIDUAL_COURSE_FAILURE: 'courses/UPDATE_CEBCODE_INDIVIDUAL_COURSE_FAILURE',

    CLEAR_CEBCODE_STATUS: 'courses/CLEAR_CEBCODE_STATUS',
    CLEAR_UPDATE_CEBCODE_INDIVIDUAL_COURSE_STATUS: 'courses/CLEAR_UPDATE_CEBCODE_INDIVIDUAL_COURSE_STATUS',
    CLEAR_UPDATE_STATUS: 'courses/CLEAR_UPDATE_STATUS',
    CLEAR_CREATE_STATUS: 'courses/CLEAR_CREATE_STATUS',

    CLEAN_CEBCODE_COURSES_REQUEST: 'courses/CLEAN_CEBCODE_COURSES_REQUEST',
    CLEAN_CEBCODE_COURSES_SUCCESS: 'courses/CLEAN_CEBCODE_COURSES_SUCCESS',
    CLEAN_CEBCODE_COURSES_FAILURE: 'courses/CLEAN_CEBCODE_COURSES_FAILURE',

    FETCH_COURSE_REQUEST: 'courses/FETCH_COURSE_REQUEST',
    FETCH_COURSE_SUCCESS: 'courses/FETCH_COURSE_SUCCESS',
    FETCH_COURSE_FAILURE: 'courses/FETCH_COURSE_FAILURE',

    UPDATE_PUBLISH_COURSE_BUILDER_REQUEST: 'courses/UPDATE_PUBLISH_COURSE_BUILDER_REQUEST',
    UPDATE_PUBLISH_COURSE_BUILDER_SUCCESS: 'courses/UPDATE_PUBLISH_COURSE_BUILDER_SUCCESS',
    UPDATE_PUBLISH_COURSE_BUILDER_FAILURE: 'courses/UPDATE_PUBLISH_COURSE_BUILDER_FAILURE',

    CLEAR_PUBLISH_COURSE_BUILDER_STATUS: 'courses/CLEAR_PUBLISH_COURSE_BUILDER_STATUS',
    CLEAR_PUBLISH_COURSE_BUILDER_ERRORS: 'courses/CLEAR_PUBLISH_COURSE_BUILDER_ERRORS',

    REFRESH_COURSE_IMAGE: 'courses/REFRESH_COURSE_IMAGE',

    REQUEST_FAILURE: 'courses/REQUEST_FAILURE'
};
