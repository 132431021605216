import axios from 'axios';
import config from '../config';

export const fetchProviderById = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const saveProvider = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/save?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const saveProviderLogo = ({ data }) => {
    return axios.request({
        url: `${config.API_URL}api/providers/savelogo?id=${data.Id}`,
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const cropProviderLogo = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/images',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveProviderBanner = ({ data }) => {
    return axios.request({
        url: `${config.API_URL}api/providers/savebanner?id=${data.Id}`,
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveDiscountProgram = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/discountprogram?id=' + data.id,
        method: 'POST',
        //headers: { 'X-HTTP-Method-Override': 'PATCH' },
        data: data,
        withCredentials: true
    });
};

export const saveCourseCompleteNotification = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/completecoursenotification?id=' + data.id,
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveCourseStartedNotification = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/startedcoursenotification?id=' + data.id,
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const integrateCEBProvider = () => {
    return axios.request({
        url: config.API_URL + 'api/providers/cebroker/integrate',
        method: 'POST',
        withCredentials: true
    });
};

export const getCEBrokerToken = (data) => {
    return axios.request({
        url: config.API_URL + 'api/providers/cebroker/token',
        method: 'POST',
        params: data,
        withCredentials: true
    });
};

export const refreshCEBrokerToken = (data) => {
    return axios.request({
        url: config.API_URL + 'api/providers/cebroker/token/refresh',
        method: 'POST',
        params: data,
        withCredentials: true
    });
};

export const removeStripeAccount = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/stripeaccount?id=' + data.providerId,
        method: 'PATCH',
        withCredentials: true
    });
};

export const toggleCEBrokerIntegration = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};