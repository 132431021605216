import at from './types';

export const fetchById = function (data) {
    return { type: at.FETCH_BY_ID_REQUEST, data };
};

export const saveProvider = function (data) {
    return { type: at.SAVE_REQUEST, data };
};

export const updateApprovals = function (data) {
    return { type: at.UPDATE_APPROVALS_REQUEST, data };
};

export const clearToggleApprovalsInUse = function () {
    return { type: at.CLEAR_TOGGLE_APPROVALS_IN_USE };
};

export const clearSaveStatusProvider = function (data) {
    return { type: at.CLEAR_SAVE_STATUS };
};

export const clearCreateStatusApprovals = function (data) {
    return { type: at.CLEAR_APPROVALS_STATUS };
};

export const saveLogo = function (data) {
    return { type: at.SAVE_LOGO_REQUEST, data };
}

export const saveBanner = function (data) {
    return { type: at.SAVE_BANNER_REQUEST, data };
}

export const clearsaveLogoStatus = function (data) {
    return { type: at.CLEAR_SAVE_LOGO_STATUS };
};

export const clearsaveBannerStatus = function (data) {
    return { type: at.CLEAR_SAVE_BANNER_STATUS };
};

export const refreshLogo = function (data) {
    return { type: at.REFRESH_LOGO };
};

export const refreshBanner = function (data) {
    return { type: at.REFRESH_BANNER };
};

export const updateDiscountProgram = function (data) {
    return { type: at.UPDATE_DISCOUNT_REQUEST, data };
};

export const removeStripeAccount = function (data) {
    return { type: at.REMOVE_STRIPE_ACCOUNT_REQUEST, data };
};

export const saveCourseCompleteNotification = function (data) {
    return { type: at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST, data };
};

export const saveCourseStartedNotification = function (data) {
    return { type: at.SAVE_COURSE_STARTED_NOTIFICATION_REQUEST, data };
};

export const clearSaveCourseCompleteNotificationStatus = function (data) {
    return { type: at.CLEAR_SAVE_COURSE_COMPLETE_NOTIFICATION_STATUS, data };
};

export const clearSaveCourseStartedNotificationStatus = function (data) {
    return { type: at.CLEAR_SAVE_COURSE_STARTED_NOTIFICATION_STATUS, data };
};

export const toggleCEBrokerIntegration = function (data) {
    return { type: at.TOGGLE_CEBROKER_INTEGRATION_REQUEST, data };
};

export const refreshCebIntegrationStatus = function (data) {
    return { type: at.REFRESH_CEB_INTEGRATION_STATUS, data };
}