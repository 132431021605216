export default function (globalState) {
    const state = globalState.surveys;

    const getSurveys = () => state.surveys;
    const getFetchStatus = () => state.fetchStatus;
    const getErrors = () => state.errorStatus;

    return {
        getSurveys,
        getFetchStatus,
        getErrors
    };
}
