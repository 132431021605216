import { put } from 'redux-saga/effects';
import at from '../actions/types';

function* removeApprovals(data) {
  try {
    yield put({ type: at.REMOVE_COURSE_APPROVALS_SUCCESS });
  } catch (error) {
    yield put({ type: at.REMOVE_COURSE_APPROVALS_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.REMOVE_COURSE_APPROVALS_REQUEST
    });
  }
}


export default { removeApprovals };