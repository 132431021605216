export default {
    FETCH_BY_ASSOCIATION_REQUEST: 'associationsmembers/FETCH_BY_ASSOCIATION_REQUEST',
    FETCH_BY_ASSOCIATION_SUCCESS: 'associationsmembers/FETCH_BY_ASSOCIATION_SUCCESS',
    FETCH_BY_ASSOCIATION_FAILURE: 'associationsmembers/FETCH_BY_ASSOCIATION_FAILURE',

    POST_MEMBER_INVITES_REQUEST: 'associationsmembers/POST_MEMBER_INVITES_REQUEST',
    POST_MEMBER_INVITES_SUCCESS: 'associationsmembers/POST_MEMBER_INVITES_SUCCESS',
    POST_MEMBER_INVITES_FAILURE: 'associationsmembers/POST_MEMBER_INVITES_FAILURE',

    CLEAR_POST_MEMBER_STATUS: 'associationsmembers/CLEAR_POST_MEMBER_STATUS',

    CANCEL_INVITE_REQUEST: 'associationsmembers/CANCEL_INVITE_REQUEST',
    CANCEL_INVITE_SUCCESS: 'associationsmembers/CANCEL_INVITE_SUCCESS',
    CANCEL_INVITE_FAILURE: 'associationsmembers/CANCEL_INVITE_FAILURE',

    CLEAR_CANCEL_STATUS: 'associationsmembers/CLEAR_CANCEL_STATUS',

    RESEND_INVITE_REQUEST: 'associationsmembers/RESEND_INVITE_REQUEST',
    RESEND_INVITE_SUCCESS: 'associationsmembers/RESEND_INVITE_SUCCESS',
    RESEND_INVITE_FAILURE: 'associationsmembers/RESEND_INVITE_FAILURE',

    CLEAR_RESEND_STATUS: 'associationsmembers/CLEAR_RESEND_STATUS',

    FETCH_MEMBER_BY_ID_REQUEST:'associationsmembers/FETCH_MEMBER_BY_ID_REQUEST',
    FETCH_MEMBER_BY_ID_SUCCESS: 'associationsmembers/FETCH_MEMBER_BY_ID_SUCCESS',
    FETCH_MEMBER_BY_ID_FAILURE: 'associationsmembers/FETCH_MEMBER_BY_ID_FAILURE',

    REMOVE_REQUEST: 'associationsmembers/REMOVE_REQUEST',
    REMOVE_SUCCESS: 'associationsmembers/REMOVE_SUCCESS',
    REMOVE_FAILURE: 'associationsmembers/REMOVE_FAILURE',
    CLEAR_REMOVE_STATUS: 'associationsmembers/CLEAR_REMOVE_STATUS',
    TOGGLE_REMOVE_MODAL: 'associationsmembers/TOGGLE_REMOVE_MODAL',

    REQUEST_FAILURE: 'associationsmembers/REQUEST_FAILURE'
}
