import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};


export const students = (state = defaultState, action) => {
    switch (action.type) {
        
        case at.FETCH_BY_COURSE_SUCCESS: {
            let { students } = action;
            return students.items;
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_COURSE_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_COURSE_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_COURSE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = defaultState, action, request) => {
    switch (action.type) {
        case at.FETCH_BY_COURSE_REQUEST: {
            return [];
        }
        case at.REQUEST_FAILURE: {
            return action;
        }
        default:
            return state;
    }
};


export default combineReducers({ students, fetchStatus, errorStatus });
