import { combineReducers } from 'redux';
import at from '../actions/types';
import _ from 'lodash';

export const coursePages = (state = {}, action) => {
    switch (action.type) {
        case at.FETCH_COURSE_PAGES_SUCCESS: {
            const { pages } = action;

            const contentPages = pages[0].data.items.map((page) => {
                return Object.assign({}, page, {
                    selected: page.selected ? page.selected : false,
                    checked: page.checked ? page.checked : false,
                    index: page.index,
                    uniqueId: _.uniqueId(),
                    type: 'page'
                });
            });

            const tests = pages[1].data.items.map((test) => {
                return Object.assign({}, test, {
                    selected: test.selected ? test.selected : false,
                    checked: test.checked ? test.checked : false,
                    index: test.index,
                    uniqueId: _.uniqueId(),
                    type: 'test'
                });
            });

            const surveys = pages[2].data.items.map((survey) => {
                return Object.assign({}, survey, {
                    selected: survey.selected ? survey.selected : false,
                    checked: survey.checked ? survey.checked : false,
                    index: survey.index,
                    uniqueId: _.uniqueId(),
                    type: 'survey'
                });
            });

            const quizzes = pages[3].data.items.map((quiz) => {
                return Object.assign({}, quiz, {
                    selected: quiz.selected ? quiz.selected : false,
                    checked: quiz.checked ? quiz.checked : false,
                    index: quiz.index,
                    uniqueId: _.uniqueId(),
                    type: 'quiz'
                });
            });

            let content = [...contentPages, ...tests, ...surveys, ...quizzes];
            content = content.sort((a, b) => a.index - b.index);
            return content;
        }

        case at.UPDATE_COURSE_CONTENT_SUCCESS: {
            const { content } = action;
            if (content) {
                return content.data;
            }
            return state;
        }

        case at.DELETE_CONTENT_SUCCESS: {
            let steps = [];
            steps.push(...action.data.removedPages.map((item) => { return { ...item, type: 'page' } }));
            steps.push(...action.data.removedTests.map((item) => { return { ...item, type: 'test' } }));
            steps.push(...action.data.removedSurveys.map((item) => { return { ...item, type: 'survey' } }));
            steps.push(...action.data.removedShortTests.map((item) => { return { ...item, type: 'quiz' } }));

            let newState = state.filter((item) => {
                let found = steps.find((step) => step.id === item.id && step.type === item.type);
                if (found) {
                    return false;
                } else {
                    return true;
                }
            });

            return newState;
        }

        case at.CREATE_TEST_SUCCESS: {
            const { result } = action;
            const newSteps = state.map((page) => {
                return { ...page, selected: false };
            });
            return newSteps.concat([{ ...result.data, type: 'test', selected: true, uniqueId: _.uniqueId() }]);
        }

        case at.UPDATE_TEST_SUCCESS: {
            const { data } = action;
            const newSteps = state.map((page) => {
                if (page.id === data.id && page.type === 'test') {
                    return { ...page, title: data.title, passPercentage: data.passPercentage, selected: true };
                } else {
                    return { ...page, selected: false };
                }
            });
            return newSteps;
        }

        case at.CREATE_QUIZ_SUCCESS: {
            const { result } = action;
            const newSteps = state.map((page) => {
                return { ...page, selected: false };
            });
            return newSteps.concat([{ ...result.data, type: 'quiz', selected: true, uniqueId: _.uniqueId() }]);
        }

        case at.UPDATE_QUIZ_SUCCESS: {
            const { data } = action;
            const newSteps = state.map((page) => {
                if (page.id === data.id && page.type === 'quiz') {
                    return { ...page, title: data.title, selected: true };
                } else {
                    return { ...page, selected: false };
                }
            });
            return newSteps;
        }

        case at.CREATE_CONTENT_PAGE_SUCCESS: {
            const { result } = action;
            const newSteps = state.map((page) => {
                return { ...page, selected: false };
            });
            return newSteps.concat([{ ...result.data, type: 'page', selected: true, uniqueId: _.uniqueId() }]);
        }

        case at.UPDATE_CONTENT_PAGE_SUCCESS: {
            const { data } = action;
            const newSteps = state.map((page) => {
                if (page.id === data.id && page.type === 'page') {
                    return { ...page, title: data.title, content: data.content, selected: true };
                } else {
                    return { ...page, selected: false };
                }
            });
            return newSteps;
        }

        case at.CREATE_SURVEY_SUCCESS: {
            const { result } = action;
            const newSteps = state.map((page) => {
                return { ...page, selected: false };
            });
            return newSteps.concat([{ ...result.data, type: 'survey', selected: true, uniqueId: _.uniqueId() }]);
        }

        case at.UPDATE_SURVEY_SUCCESS: {
            const { data } = action;
            const newSteps = state.map((page) => {
                if (page.id === data.id && page.type === 'survey') {
                    return { ...page, title: data.title, description: data.description, selected: true };
                } else {
                    return { ...page, selected: false };
                }
            });
            return newSteps;
        }

        case at.UPDATE_CONTENT_PAGE_TIMER_SUCCESS: {
            const { data } = action;
            const newSteps = state.map((page) => {
                if (page.id === data.id && page.type === 'page') {
                    return { ...page, timer: data.timer, selected: true };
                } else {
                    return { ...page, selected: false };
                }
            });
            return newSteps;
        }

        default:
            return state;
    }
};

export const fetchCoursePagesStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_COURSE_PAGES_REQUEST: {
            return 'loading';
        }
        case at.FETCH_COURSE_PAGES_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_COURSE_PAGES_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const updateCourseContentStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_COURSE_CONTENT_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_COURSE_CONTENT_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_COURSE_CONTENT_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const updatePagesIndexesStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_PAGE_INDEXES_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_PAGE_INDEXES_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_PAGE_INDEXES_FAILURE: {
            return 'failed';
        }
        case at.UPDATE_PAGE_INDEXES_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const deleteContentStatus = (state = 'notDeleted', action) => {
    switch (action.type) {
        case at.DELETE_CONTENT_REQUEST: {
            return 'deleting';
        }
        case at.DELETE_CONTENT_SUCCESS: {
            return 'deleted';
        }
        case at.DELETE_CONTENT_FAILURE: {
            return 'failed';
        }
        case at.DELETE_CONTENT_STATUS: {
            return 'notDeleted';
        }
        default:
            return state;
    }
};

export const createTestStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_TEST_REQUEST: {
            return 'creating';
        }
        case at.CREATE_TEST_SUCCESS: {
            return 'created';
        }
        case at.CREATE_TEST_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_TEST_STATUS: {
            return 'notCreated';
        }
        default:
            return state;
    }
};

export const updateTestStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_TEST_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_TEST_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_TEST_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_TEST_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const createQuizStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_QUIZ_REQUEST: {
            return 'creating';
        }
        case at.CREATE_QUIZ_SUCCESS: {
            return 'created';
        }
        case at.CREATE_QUIZ_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_QUIZ_STATUS: {
            return 'notCreated';
        }
        default:
            return state;
    }
};

export const updateQuizStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_QUIZ_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_QUIZ_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_QUIZ_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_QUIZ_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const createContentPageStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_CONTENT_PAGE_REQUEST: {
            return 'creating';
        }
        case at.CREATE_CONTENT_PAGE_SUCCESS: {
            return 'created';
        }
        case at.CREATE_CONTENT_PAGE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_CONTENT_PAGE_STATUS: {
            return 'notCreated';
        }
        default:
            return state;
    }
};

export const updateContentPageStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_CONTENT_PAGE_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_CONTENT_PAGE_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_CONTENT_PAGE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_CONTENT_PAGE_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const updateContentPageTimerStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_CONTENT_PAGE_TIMER_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_CONTENT_PAGE_TIMER_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_CONTENT_PAGE_TIMER_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_CONTENT_PAGE_TIMER_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const createSurveyStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_SURVEY_REQUEST: {
            return 'creating';
        }
        case at.CREATE_SURVEY_SUCCESS: {
            return 'created';
        }
        case at.CREATE_SURVEY_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_SURVEY_STATUS: {
            return 'notCreated';
        }
        default:
            return state;
    }
};

export const updateSurveyStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_SURVEY_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_SURVEY_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_SURVEY_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_SURVEY_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const createdStep = (state = {}, action) => {
    switch (action.type) {
        case at.CREATE_TEST_SUCCESS: {
            const { result } = action;
            return { ...result.data, type: 'test' };
        }
        case at.CREATE_QUIZ_SUCCESS: {
            const { result } = action;
            return { ...result.data, type: 'quiz' };
        }
        case at.CREATE_CONTENT_PAGE_SUCCESS: {
            const { result } = action;
            return { ...result.data, type: 'page' };
        }
        case at.CREATE_SURVEY_SUCCESS: {
            const { result } = action;
            return { ...result.data, type: 'survey' };
        }
        default:
            return state;
    }
};

export const errorStatus = (state = {}, action) => {
    let errors = {};

    if (action.type === at.REQUEST_FAILURE) {
        switch (action.requestFailed) {
            case (at.FETCH_COURSE_PAGES_REQUEST):
                errors.FETCH_COURSE_PAGES_REQUEST = action.message;
                break;
            case (at.UPDATE_COURSE_CONTENT_REQUEST):
                errors.UPDATE_COURSE_CONTENT_REQUEST = action.message;
                break;
            case (at.CREATE_CONTENT_PAGE_REQUEST):
                errors.CREATE_CONTENT_PAGE_REQUEST = action.message;
                break;
            case (at.UPDATE_CONTENT_PAGE_REQUEST):
                errors.UPDATE_CONTENT_PAGE_REQUEST = action.message;
                break;
            case (at.UPDATE_CONTENT_PAGE_TIMER_REQUEST):
                errors.UPDATE_CONTENT_PAGE_TIMER_REQUEST = action.message;
                break;
            case (at.UPDATE_PAGE_INDEXES_REQUEST):
                errors.UPDATE_PAGE_INDEXES_REQUEST = action.message;
                break;
            case (at.DELETE_CONTENT_REQUEST):
                errors.DELETE_CONTENT_REQUEST = action.message;
                break;
            case (at.CREATE_QUIZ_REQUEST):
                errors.CREATE_QUIZ_REQUEST = action.message;
                break;
            case (at.UPDATE_QUIZ_REQUEST):
                errors.UPDATE_QUIZ_REQUEST = action.message;
                break;
            case (at.CREATE_TEST_REQUEST):
                errors.CREATE_TEST_REQUEST = action.message;
                break;
            case (at.UPDATE_TEST_REQUEST):
                errors.UPDATE_TEST_REQUEST = action.message;
                break;
            case (at.CREATE_SURVEY_REQUEST):
                errors.CREATE_SURVEY_REQUEST = action.message;
                break;
            case (at.UPDATE_SURVEY_REQUEST):
                errors.UPDATE_SURVEY_REQUEST = action.message;
                break;
            default:
                break;
        }
    }

    return errors;
};

export default combineReducers({ coursePages, fetchCoursePagesStatus, updateCourseContentStatus, updatePagesIndexesStatus, deleteContentStatus, createTestStatus, updateTestStatus, createQuizStatus, updateQuizStatus, createContentPageStatus, updateContentPageStatus, updateContentPageTimerStatus, createSurveyStatus, updateSurveyStatus, createdStep, errorStatus });
