import axios from 'axios';
import config from '../config';

export const fetch = ({ data }) => {
    return axios.request({
        url: config.API_URL + `api/certificateConfig/${data}`,
        method: 'GET',
        withCredentials: true
    });
};

export const saveConfigurationCertificate = ({ data }) => {
    return axios.request({
        url: config.API_URL + `api/certificateConfig?id=${data.id}`,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const update = ({ data }) => {
    return axios.request({
        url: config.API_URL + `api/certificateConfig?id=${data.id}`,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

