import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  Avatar,
  NavDropdown,
  MenuItem,
  Alert,
  IconButton
} from 'emerald-ui';
import AppBar from '../AppBar';
import Main from '../Main';
import tablemesaLogo from './tablemesa-logo.svg';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import AppFooter from '../AppFooter';
import Cookies from 'universal-cookie';
import config from '../../config';
import Intercom from 'react-intercom';
import LogRocket from 'logrocket';
import './App.css';
import { serveImage } from '../../helpers/utils';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAside: false,
      intercomSettings: {}
    };

    const { userFetchStatus, fetchLoggedInUser } = this.props;
    if (userFetchStatus === 'notLoaded') {
      fetchLoggedInUser();
    }
    if (config.LOGROCKET_ENABLED) {
      LogRocket.init(`${config.LOGROCKET_APPID}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, userFetchStatus } = nextProps;

    if (userFetchStatus === 'loaded') {
      if (config.INTERCOM_ENABLED && !user.isAdmin) {
        const settings = {
          app_id: config.INTERCOM_APPID,
          name: user.firstName + ' ' + user.lastName, // Full name
          email: user.userName, // Email address
          created_at: user.providerDateAdded // Signup date as a Unix timestamp
        };

        this.setState({ intercomSettings: settings });
      }
      if (config.LOGROCKET_ENABLED) {
        LogRocket.identify(user.userName, {
          name: user.firstName + ' ' + user.lastName,
          email: user.userName
        });
      }
    }
  }

  onShowAside = () => {
    this.setState({ showAside: !this.state.showAside });
  }

  logoutUser() {
    const cookies = new Cookies();
    cookies.remove('.AspNet.ApplicationCookie', { domain: config.DOMAIN });
    window.location.replace(config.API_URL);
  }

  hideLoader() {
    let loaderInit = document.querySelector('.loader-init');
    if (loaderInit) {
      loaderInit.style.display = 'none';
    }
  }

  goToAdmin() {
    window.location.href = `${config.API_URL}/admin/provider/revoke`;
  }

  render() {
    const { user, userFetchStatus } = this.props;
    if (userFetchStatus === "loaded" || userFetchStatus === "failed") {
      this.hideLoader();
    }

    let regex = /course\/edit\/[0-9]/;
    let courseBuilder = regex.test(window.location.pathname);

    return (
      <div className="App">
        {userFetchStatus === 'failed' && (
          <Alert color="danger" className="m-t">
            There was an error loading the user information
          </Alert>
        )}
        {userFetchStatus === 'loaded' && (
          <div>
            {!courseBuilder && this.state.showAside && (
              <div className="nav-off-screen">
                <div className="content-avatar">
                  <Avatar
                    title={!user.providerLogoUrl ? `${user.firstName} ${user.lastName}` : ''}
                    size="lg"
                    style={{ marginBottom: '10px', backgroundImage: user.providerLogoUrl ? 'url(' + serveImage(user.providerLogoUrl) + ')' : 'unset' }}
                  />
                  <span>{user.firstName} {user.lastName}</span>
                  <p className="role">Educator</p>
                </div>
                <Nav>
                  <IndexLinkContainer to="/">
                    <NavItem eventKey={1} onClick={this.onShowAside}>Dashboard</NavItem>
                  </IndexLinkContainer>
                  <LinkContainer to="/courses">
                    <NavItem eventKey={2} onClick={this.onShowAside}>Courses</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/transactions/history">
                    <NavItem eventKey={3} onClick={this.onShowAside}>Transactions</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/activity">
                    <NavItem eventKey={4} onClick={this.onShowAside}>Activity</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/members">
                    <NavItem eventKey={9} onClick={this.onShowAside}>Members</NavItem>
                  </LinkContainer>
                  {!user.isAssociationsCourseEditor && (
                    <LinkContainer to="/account/profile">
                      <NavItem eventKey={5} onClick={this.onShowAside}>Edit public profile</NavItem>
                    </LinkContainer>
                  )}
                  <NavDivider />
                  <LinkContainer to="/account/settings/general" className="menu-item">
                    <NavItem eventKey={6} onClick={this.onShowAside}>
                      <i className="material-icons">settings</i>
                      Account settings
                    </NavItem>
                  </LinkContainer>
                  {user.isAdmin && (
                    <LinkContainer to="#" className="menu-item">
                      <NavItem eventKey={7} onClick={this.goToAdmin}>
                        <i className="material-icons">reply</i>
                        Go to Admin
                      </NavItem>
                    </LinkContainer>
                  )}
                  <LinkContainer to="#" className="menu-item">
                    <NavItem eventKey={8} onClick={this.logoutUser}>
                      <i className="material-icons">exit_to_app</i>
                      Sign out
                    </NavItem>
                  </LinkContainer>
                </Nav>
              </div>
            )}

            {!courseBuilder && (
              <AppBar>
                <AppBar.Header>
                  <AppBar.Brand target="/" className="App-brand">
                    {!user.isAssociations && (
                      <img src={tablemesaLogo} alt="Table Mesa" />
                    )}
                    {user.isAssociations && (
                      <div>
                        <img src={tablemesaLogo} alt="Table Mesa" width="140" />
                        <span className="associations-logo">ASSOCIATIONS</span>
                      </div>
                    )}
                    <LinkContainer to="#">
                      <IconButton icon="menu" className="menu-icon" onClick={this.onShowAside} />
                    </LinkContainer>
                  </AppBar.Brand>
                </AppBar.Header>
                <Nav>
                  <IndexLinkContainer to="/">
                    <NavItem eventKey={1}>Dashboard</NavItem>
                  </IndexLinkContainer>
                  <LinkContainer to="/courses">
                    <NavItem eventKey={2}>Courses</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/transactions/history">
                    <NavItem eventKey={3}>Transactions</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/activity">
                    <NavItem eventKey={4}>Activity</NavItem>
                  </LinkContainer>
                  {user.isAssociations && (
                    <LinkContainer to="/members">
                      <NavItem eventKey={5}>Members</NavItem>
                    </LinkContainer>
                  )}
                </Nav>
                <div className="pull-right dropdown-avatar">
                  <Nav pullRight>
                    {!user.isAssociationsCourseEditor && (
                      <LinkContainer to="/account/profile" className={user.isAssociations ? 'hidden-sm' : ''}>
                        <NavItem eventKey={6}>Edit public profile</NavItem>
                      </LinkContainer>
                    )}
                    <NavDropdown
                      className="avatar-dropdown"
                      eventKey={7}
                      title={<Avatar title={!user.providerLogoUrl ? `${user.firstName} ${user.lastName}` : ''}
                        style={{ backgroundImage: user.providerLogoUrl ? 'url(' + serveImage(user.providerLogoUrl) + ')' : 'unset' }} />}
                      id="test-nav-dropdown"
                      pullRight
                      noCaret
                    >
                      <MenuItem
                        header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '11px',
                          paddingBottom: '10px',
                          minWidth: '217px'
                        }}
                      >
                        <Avatar
                          title={!user.providerLogoUrl ? `${user.firstName} ${user.lastName}` : ''}
                          size="lg"
                          style={{ marginRight: '20px', backgroundImage: user.providerLogoUrl ? 'url(' + serveImage(user.providerLogoUrl) + ')' : 'unset' }}
                        />
                        <div style={{ display: 'inline-block' }}>
                          <p className="username">{`${user.firstName} ${user.lastName}`}</p>
                          <p className="subtitle">Educator</p>
                        </div>
                      </MenuItem>
                      <MenuItem divider />
                      <LinkContainer to="/account/settings/general">
                        <MenuItem icon="settings" eventKey={3.1}>
                          Account settings
                          </MenuItem>
                      </LinkContainer>
                      {user.isAssociations && !user.isAssociationsCourseEditor && (
                        <LinkContainer to="/account/profile" className="hidden-lg hidden-md" key={1}>
                          <MenuItem icon="person" eventKey={3.2}>
                            Edit public profile
                          </MenuItem>
                        </LinkContainer>
                      )}
                      {user.isAssociations && (
                        <LinkContainer to="/users" key={2}>
                          <MenuItem icon="people" eventKey={3.3}>
                            Users
                        </MenuItem>
                        </LinkContainer>
                      )}
                      <MenuItem divider />
                      {user.isAdmin && (
                        <LinkContainer to="#">
                          <MenuItem id="go-to-admin" icon="reply" eventKey={3.4} onClick={this.goToAdmin}>
                            Go to Admin
                          </MenuItem>
                        </LinkContainer>
                      )}
                      <LinkContainer to="#">
                        <MenuItem id="logout" icon="exit_to_app" eventKey={3.5} onClick={this.logoutUser}>
                          Sign out
                        </MenuItem>
                      </LinkContainer>
                    </NavDropdown>
                  </Nav>
                </div>
              </AppBar>
            )}

            {userFetchStatus === 'loaded' && (
              <Main user={user} />
            )}

            {!courseBuilder && (
              <AppFooter link="mailto:genevieve@mytablemesa.com" />
            )}

            {config.INTERCOM_ENABLED && !user.isAdmin && (
              <Intercom appID={config.INTERCOM_APPID} {...this.state.intercomSettings} />
            )}
          </div>
        )}
      </div>
    );
  }
}

function NavDivider() {
  return <li role="separator" className="divider" />
}

export default App;
