import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = [];
const defaultCouponState = {
    href: null,
    licenseeCoupons: []
};

export const coupons = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_REQUEST:
        case at.FETCH_FAILURE: {
            return defaultState;
        }
        case at.FETCH_SUCCESS: {
            const { coupons: { items = [] } } = action;
            return items;
        }
        case at.CREATE_SUCCESS: {
            const { newCoupon } = action;
            const newItem = { ...defaultCouponState, ...newCoupon };
            state.unshift(newItem);
            return state;
        }
        case at.DELETE_SUCCESS: {
            const { couponId } = action;
            return state.filter(c => c.id !== couponId);
        }
        case at.UPDATE_SUCCESS: {
            const { coupon } = action;
            let newState = state.map(c => {
                if (c.id === coupon.data.id) {
                    return { ...c, name: coupon.data.newName };
                }
                return c;
            });
            return newState;
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_REQUEST: {
            return 'loading';
        }
        case at.FETCH_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchStatusNext = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_SUCCESS: {
            const { coupons: { next } } = action;
            return next ? true : false;
        }
        default:
            return state;
    }
};

export const fetchStatusPrev = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_SUCCESS: {
            const { coupons: { previous } } = action;
            return previous ? true : false;
        }
        default:
            return state;
    }
};

export const selectedCoupon = (state = defaultCouponState, action) => {
    switch (action.type) {
        case at.FETCH_COUPON_REQUEST:
        case at.FETCH_COUPON_SUCCESS: {
            const { coupon } = action;
            if (coupon) {
                return coupon;
            }
            return state;
        }
        case at.FETCH_COUPON_FAILURE: {
            return defaultState;
        }
        case at.UPDATE_SUCCESS: {
            const { coupon } = action;
            state = { ...state, name: coupon.data.newName };
            return state;
        }
        default:
            return state;
    }
};

export const fetchSelectedCouponStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_COUPON_REQUEST: {
            return 'loading';
        }
        case at.FETCH_COUPON_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_COUPON_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const couponPerformance = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_COUPON_PERFORMANCE_REQUEST: {
            return state;
        }
        case at.FETCH_COUPON_PERFORMANCE_SUCCESS: {
            const { performance } = action;
            return performance;
        }
        case at.FETCH_COUPON_PERFORMANCE_FAILURE: {
            return state;
        }
        default:
            return state;
    }
};


export const fetchCouponPerformanceStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_COUPON_PERFORMANCE_REQUEST: {
            return 'loading';
        }
        case at.FETCH_COUPON_PERFORMANCE_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_COUPON_PERFORMANCE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const createStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_REQUEST: {
            return 'creating';
        }
        case at.CREATE_SUCCESS: {
            return 'created';
        }
        case at.CREATE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const deleteStatus = (state = 'notDeleted', action) => {
    switch (action.type) {
        case at.DELETE_REQUEST: {
            return 'deleting';
        }
        case at.DELETE_SUCCESS: {
            return 'deleted';
        }
        case at.DELETE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_DELETE_STATUS: {
            return 'notDeleted';
        }
        default:
            return state;
    }
};

export const showCreatingModalStatus = (state = false, action) => {
    switch (action.type) {
        case at.TOGGLE_COUPON_MODAL: {
            const { showModal } = action;
            return showModal;
        }
        default:
            return state;
    }
};

export const updateStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_REQUEST:
        case at.FETCH_COUPON_REQUEST:
        case at.CREATE_REQUEST:
        case at.DELETE_REQUEST: {
            return [];
        }
        case at.REQUEST_FAILURE: {
            return action;
        }
        case at.FETCH_COUPON_PERFORMANCE_REQUEST:
        default:
            return state;
    }
};

export default combineReducers({ coupons, fetchStatus, fetchStatusNext, fetchStatusPrev, selectedCoupon, fetchSelectedCouponStatus, createStatus, showCreatingModalStatus, deleteStatus, updateStatus, errorStatus, couponPerformance, fetchCouponPerformanceStatus });
