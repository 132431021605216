import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const certificateConfig = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_CERTIFICATE_CONFIG_SUCCESS: {
            const { config } = action;
            return config;
        }
        case at.UPDATE_CERTIFICATE_CONFIG_SUCCESS: {
            const data = action.config.data;
            return { ...state, ...data };
        }
        default:
            return state;
    }
};

export const fetchCertificateConfigStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_CERTIFICATE_CONFIG_REQUEST: {
            return 'loading';
        }
        case at.FETCH_CERTIFICATE_CONFIG_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_CERTIFICATE_CONFIG_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};


export const updateCertificateConfigStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_CERTIFICATE_CONFIG_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_CERTIFICATE_CONFIG_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_CERTIFICATE_CONFIG_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_CERTIFICATE_CONFIG_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = {}, action) => {
    let errors = {};

    if (action.type === at.REQUEST_FAILURE) {
        switch (action.requestFailed) {
            case (at.FETCH_CERTIFICATE_CONFIG_REQUEST):
                errors.FETCH_CERTIFICATE_CONFIG_REQUEST = action.message;
                break;
            case (at.UPDATE_CERTIFICATE_CONFIG_REQUEST):
                errors.UPDATE_CERTIFICATE_CONFIG_REQUEST = action.message;
                break;
            default:
                break;
        }
    }

    return errors;
};

export default combineReducers({ certificateConfig, fetchCertificateConfigStatus, updateCertificateConfigStatus, errorStatus });
