import { combineReducers } from 'redux';

import at from '../actions/types';

const defaultState = {
  firstName: '',
  lastName: ''
};

export const user = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_REQUEST:
    case at.FETCH_FAILURE: {
      return defaultState;
    }
    case at.FETCH_SUCCESS: {
      const { user } = action;

      const isAdmin = user.role === 'Admin';
      const isAssociationsOwner = user.role === 'Associations' || (isAdmin && user.providerUserRole === 'Associations');
      const isAssociationsAdmin = user.role === 'AssociationsAdmin';
      const isAssociationsCourseEditor = user.role === 'AssociationsCourseEditor';
      const isAssociations = isAssociationsOwner || isAssociationsAdmin || isAssociationsCourseEditor;

      return { ...user, isAdmin, isAssociations, isAssociationsOwner, isAssociationsAdmin, isAssociationsCourseEditor };
    }
    case at.UPDATE_SUCCESS: {
      const { user } = action;

      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.userName = user.userName;

      return state;
    }
    case at.UPDATE_REQUEST:
    case at.UPDATE_FAILURE: {
      return state;
    }
    case at.SET_USER_PROVIDER_LOGO: {
      const { logoUrl } = action;
      return { ...state, providerLogoUrl: logoUrl };
    }
    case at.REFRESH_USER_PROVIDER_LOGO: {
      return { ...state, providerLogoUrl: state.providerLogoUrl + '?' + new Date().getTime() };
    }

    case at.CONVERT_USER_ASSOCIATIONS_SUCCESS: {
      return { ...state, isAssociations: true, isAssociationsOwner: true, isAssociationsAdmin: false, isAssociationsCourseEditor: false, providerUserRole: 'Associations' };
    }
    default:
      return state;
  }
};


export const fetchStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const updateStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const convertUserToAssociationsStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.CONVERT_USER_ASSOCIATIONS_REQUEST: {
      return 'updating';
    }
    case at.CONVERT_USER_ASSOCIATIONS_SUCCESS: {
      return 'updated';
    }
    case at.CONVERT_USER_ASSOCIATIONS_FAILURE: {
      return 'failed';
    }
    case at.CONVERT_USER_ASSOCIATIONS_STATUS: {
      return 'notUpdated';
    }
    default:
      return state;
  }
};


export const errorStatus = (state = defaultState, action) => {
  switch (action.type) {
    case at.REQUEST_FAILURE: {
      return action;
    }
    default:
      return state;
  }
};

export default combineReducers({ user, fetchStatus, updateStatus, convertUserToAssociationsStatus, errorStatus });
