export default function (globalState) {
    const state = globalState.courses;

    const getCourses = () => state.courses;
    const getFetchStatus = () => state.fetchStatus;
    const getCreateStatus = () => state.createStatus;
    const getUpdateFeatureStatus = () => state.updateFeatureStatus;
    const getUpdatePublishStatus = () => state.updatePublishStatus;
    const getUpdatePublishCourseBuilderStatus = () => state.updatePublishCourseBuilderStatus;
    const getErrors = () => state.errorStatus;
    const getProviderCoursesNextStatus = () => state.fetchStatusNext;
    const getProviderCoursesPrevStatus = () => state.fetchStatusPrev;
    const getUpdateCebCodeStatus = () => state.updateCebCodeStatus;
    const getUpdateCebCodeIndividualCourseStatus = () => state.updateCebCodeIndividualCourseStatus;
    const getPublished = () => state.published;
    const getUnPublished = () => state.unPublished;
    const getDrafts = () => state.drafts;
    const getCourse = () => state.course;
    const getFetchCourseStatus = () => state.fetchCourseStatus;
    const getUpdateStatus = () => state.updateStatus;
    const getPublishingErrors = () => state.publishingErrors;

    return {
        getCourses,
        getFetchStatus,
        getCreateStatus,
        getUpdateFeatureStatus,
        getUpdatePublishStatus,
        getErrors,
        getProviderCoursesNextStatus,
        getProviderCoursesPrevStatus,
        getUpdateCebCodeStatus,
        getUpdateCebCodeIndividualCourseStatus,
        getPublished,
        getUnPublished,
        getDrafts,
        getCourse,
        getFetchCourseStatus,
        getUpdateStatus,
        getUpdatePublishCourseBuilderStatus,
        getPublishingErrors
    };
}
