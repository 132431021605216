export default {
    FETCH_CERTIFICATE_CONFIG_REQUEST: 'certificateConfig/FETCH_CERTIFICATE_CONFIG_REQUEST',
    FETCH_CERTIFICATE_CONFIG_SUCCESS: 'certificateConfig/FETCH_CERTIFICATE_CONFIG_SUCCESS',
    FETCH_CERTIFICATE_CONFIG_FAILURE: 'certificateConfig/FETCH_CERTIFICATE_CONFIG_FAILURE',

    UPDATE_CERTIFICATE_CONFIG_REQUEST: 'certificateConfig/UPDATE_CERTIFICATE_CONFIG_REQUEST',
    UPDATE_CERTIFICATE_CONFIG_SUCCESS: 'certificateConfig/UPDATE_CERTIFICATE_CONFIG_SUCCESS',
    UPDATE_CERTIFICATE_CONFIG_FAILURE: 'certificateConfig/UPDATE_CERTIFICATE_CONFIG_FAILURE',
    
    CLEAR_UPDATE_CERTIFICATE_CONFIG_STATUS: 'certificateConfig/CLEAR_UPDATE_CERTIFICATE_CONFIG_STATUS',

    REQUEST_FAILURE: 'certificateConfig/REQUEST_FAILURE'
};
