import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/states';
import at from '../actions/types';

function* fetch(data) {
    try {
        const result = yield call(api.fetchStates);
        yield put({ type: at.FETCH_SUCCESS, states: result.data });
    } catch (error) {
        yield put({ type: at.FETCH_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.FETCH_REQUEST
        });
    }
}

export default { fetch }
