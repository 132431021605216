import { call, put } from 'redux-saga/effects';
import * as apiContentPage from '../../../api/courseContentPages.js';

import at from '../actions/types';

function* createContentPage({ data }) {
    try {
        const result = yield call(apiContentPage.createContentPage, data);
        yield put({ type: at.CREATE_CONTENT_PAGE_SUCCESS, result });
    } catch (error) {
        yield put({ type: at.CREATE_CONTENT_PAGE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.CREATE_CONTENT_PAGE_REQUEST
        });
    }
}

function* updateContentPage({ data }) {
    try {
        yield call(apiContentPage.updateContentPage, data);
        yield put({ type: at.UPDATE_CONTENT_PAGE_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_CONTENT_PAGE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_CONTENT_PAGE_REQUEST
        });
    }
}

function* updateContentPageTimer({ data }) {
    try {
        yield call(apiContentPage.updateContentPageTimer, data);
        yield put({ type: at.UPDATE_CONTENT_PAGE_TIMER_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_CONTENT_PAGE_TIMER_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_CONTENT_PAGE_TIMER_REQUEST
        });
    }
}

export default { createContentPage, updateContentPage, updateContentPageTimer };
