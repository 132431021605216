import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providerUsers';
import at from '../actions/types';

export default function* remove(data) {
  try {
    yield call(api.removeProviderUser, data);
    yield put({ type: at.REMOVE_SUCCESS });
  } catch (error) {
    yield put({ type: at.REMOVE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.REMOVE_REQUEST
    });
  }
}