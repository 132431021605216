import axios from 'axios';
import config from '../config';

export const fetchCoupons = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coupons/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchCoupon = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coupons/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const getCouponCourses = ({ data }) => {
    return axios.request({
        url: `${config.API_URL}api/coupons/${data.id}/courses`,
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const createCoupon = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coupons',
        method: 'POST',
        data: data.coupon,
        params: {
            isRelatedToAllCourses: data.isRelatedToAllCourses,
            courses: data.courses
        },
        withCredentials: true
    });
};

export const deleteCoupon = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coupons/disable',
        method: 'PATCH',
        params: data,
        withCredentials: true
    });
};

export const updateCouponName = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coupons',
        method: 'PATCH',
        params: data,
        withCredentials: true
    });
};

export const fetchCouponPerformance = ({ data }) => {
    return axios.request({
        url: `${config.API_URL}api/coupons/performance?id=` + data.id,
        method: 'GET',
        withCredentials: true
    });
};

