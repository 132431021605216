import at from './types';

export const fetchBySurvey = function (data) {
    return { type: at.FETCH_BY_SURVEY_REQUEST, data };
};

export const fetchByTest = function (data) {
    return { type: at.FETCH_BY_TEST_REQUEST, data };
};

export const fetchNext = function (url) {
    return { type: at.FETCH_NEXT_REQUEST, url };
};

export const clearFetchStatus = function (data) {
    return { type: at.FETCH_CLEAR_STATUS };
};
