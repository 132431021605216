export default {
    FETCH_BY_SURVEY_REQUEST: 'licensees/FETCH_BY_SURVEY_REQUEST',
    FETCH_BY_SURVEY_SUCCESS: 'licensees/FETCH_BY_SURVEY_SUCCESS',
    FETCH_BY_SURVEY_FAILURE: 'licensees/FETCH_BY_SURVEY_FAILURE',

    FETCH_BY_TEST_REQUEST: 'licensees/FETCH_BY_TEST_REQUEST',
    FETCH_BY_TEST_SUCCESS: 'licensees/FETCH_BY_TEST_SUCCESS',
    FETCH_BY_TEST_FAILURE: 'licensees/FETCH_BY_TEST_FAILURE',

    FETCH_NEXT_REQUEST: 'licensees/FETCH_NEXT_REQUEST',
    FETCH_NEXT_SUCCESS: 'licensees/FETCH_NEXT_SUCCESS',
    FETCH_NEXT_FAILURE: 'licensees/FETCH_NEXT_FAILURE',

    FETCH_CLEAR_STATUS: 'licensees/FETCH_CLEAR_STATUS',

    REQUEST_FAILURE: 'licensees/REQUEST_FAILURE'
}