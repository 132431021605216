import axios from 'axios';
import config from '../config';

export const getByAssociationId = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers',
        method: 'GET',
        withCredentials: true,
        params: data
    });
};

export const getById = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers/' + data.id,
        method: 'GET',
        withCredentials: true
    });
};

export const removeMember = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers?id=' + data.id,
        method: 'DELETE',
        withCredentials: true
    });
};

export const postMemberInvites = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers',
        method: 'POST',
        withCredentials: true,
        data: data
    });
};

export const resendInvite = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers/resendInvite',
        method: 'POST',
        withCredentials: true,
        params: data
    });
};

export const cancelInvite = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/associationsmembers/cancelInvite',
        method: 'POST',
        withCredentials: true,
        params: data
    });
};
