import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import { fetchByProvider, fetchById } from './fetch';
import remove from './remove';
import create from './create';
import { updateBasicInfo, updateRole } from './update';

export default function* root() {
    yield takeLatest(at.FETCH_BY_PROVIDER_REQUEST, fetchByProvider);
    yield takeLatest(at.CREATE_REQUEST, create);
    yield takeLatest(at.UPDATE_BASIC_INFO_REQUEST, updateBasicInfo);
    yield takeLatest(at.UPDATE_ROLE_REQUEST, updateRole);
	yield takeLatest(at.REMOVE_REQUEST, remove);
    yield takeLatest(at.FETCH_BY_ID_REQUEST, fetchById);
}
