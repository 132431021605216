import { combineReducers } from 'redux';
import { rootReducer as loggedInUser } from './loggedInUser';
import { rootReducer as rosters } from './rosters';
import { rootReducer as revenue } from './revenue';
import { rootReducer as courses } from './courses';
import { rootReducer as providers } from './providers';
import { rootReducer as coupons } from './coupons';
import { rootReducer as courseCoupon } from './courseCoupon';
import { rootReducer as states } from './states';
import { rootReducer as bankAccount } from './bankAccount';
import { rootReducer as CEBCourses } from './CEBCourses';
import { rootReducer as courseBuilder } from './courseBuilder';
import { rootReducer as certificateConfig } from './certificateConfig';
import { rootReducer as approvals } from './approvals';
import { rootReducer as students } from './students';
import { rootReducer as surveys } from './surveys';
import { rootReducer as tests } from './tests';
import { rootReducer as licensees } from './licensees';
import { rootReducer as associationsMembers } from './associationsMembers';
import { rootReducer as providerUsers } from './providerUsers';

const rootReducer = combineReducers({
  loggedInUser,
  rosters,
  revenue,
  courses,
  providers,
  coupons,
  courseCoupon,
  states,
  bankAccount,
  CEBCourses,
  courseBuilder,
  certificateConfig,
  approvals,
  students,
  surveys,
  tests,
  licensees,
  associationsMembers,
  providerUsers
});

export default rootReducer;

