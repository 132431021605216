import { takeLatest, takeEvery } from 'redux-saga/effects';
import at from '../actions/types';
import fetch from './fetch';
import create from './create';
import deleteCoupon from './delete';
import update from './update';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch.fetch);
  yield takeLatest(at.CREATE_REQUEST, create);
  yield takeLatest(at.DELETE_REQUEST, deleteCoupon);
  yield takeLatest(at.FETCH_COUPON_REQUEST, fetch.fetchSelectedCoupon);
  yield takeLatest(at.UPDATE_REQUEST, update);
  yield takeLatest(at.FETCH_COUPON_PERFORMANCE_REQUEST, fetch.fetchCouponPerformance);
}

