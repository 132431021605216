export default function (globalState) {
    const state = globalState.certificateConfig;

    const getCertificateConfig = () => state.certificateConfig;
    const getCertificateConfigStatus = () => state.fetchCertificateConfigStatus;
    const getUpdateCertificateConfigStatus = () => state.updateCertificateConfigStatus;
    const getErrors = () => state.errorStatus;

    return {
        getCertificateConfig,
        getCertificateConfigStatus,
        getUpdateCertificateConfigStatus,
        getErrors
    };
}
