
import at from './types';

export const fetchProviderApprovals = function (data) {
    return { type: at.FETCH_BY_PROVIDER_REQUEST, data };
};

export const fetchCourseApprovals = function (data) {
    return { type: at.FETCH_BY_COURSE_REQUEST, data };
};

export const removeCourseApprovals = function (data) {
    return { type: at.REMOVE_COURSE_APPROVALS_REQUEST };
};

export const addCourseApprovals = function (data) {
    return { type: at.ADD_COURSE_APPROVALS_REQUEST, data };
};

export const addApprovals = function (data) {
    return { type: at.ADD_APPROVALS_REQUEST, data };
};