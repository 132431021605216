import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const licensees = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_BY_SURVEY_SUCCESS: {
            let { licensees } = action;
            return {
                items: licensees.items,
                next: licensees.next
            };
        }
        case at.FETCH_BY_TEST_SUCCESS: {
            let { licensees } = action;
            return {
                items: licensees.items,
                next: licensees.next
            };
        }
        case at.FETCH_NEXT_SUCCESS: {
            let { licensees } = action;
            licensees.items = state.items.concat(licensees.items);
            return {
                items: licensees.items,
                next: licensees.next
            };
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_SURVEY_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_SURVEY_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_SURVEY_FAILURE: {
            return 'failed';
        }
        case at.FETCH_BY_TEST_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_TEST_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_TEST_FAILURE: {
            return 'failed';
        }
        case at.FETCH_CLEAR_STATUS: {
            return 'notLoaded';
        }
        default:
            return state;
    }
};

export const fetchNextStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_NEXT_REQUEST: {
            return 'loading';
        }
        case at.FETCH_NEXT_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_NEXT_FAILURE: {
            return 'failed';
        }
        case at.FETCH_CLEAR_STATUS: {
            return 'notLoaded';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = defaultState, action, request) => {
    switch (action.type) {
        case at.FETCH_BY_SURVEY_REQUEST: {
            return [];
        }
        case at.REQUEST_FAILURE: {
            return action;
        }
        default:
            return state;
    }
};

export default combineReducers({ licensees, fetchStatus, fetchNextStatus, errorStatus });
