import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';

export default function* saveCourseCompleteNotification({ data }) {
  try {
    yield call(api.saveCourseCompleteNotification, { data: data });
    yield put({ type: at.SAVE_COURSE_COMPLETE_NOTIFICATION_SUCCESS, provider: data });
  } catch (error) {
    yield put({ type: at.SAVE_COURSE_COMPLETE_NOTIFICATION_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST
    });
  }
}
