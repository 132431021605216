const CollectionResult = {
    href: '',
    first: '',
    previous: '',
    next: '',
    last: '',
    offset: 0,
    limit: 25,
    size: null,
    items: []
};

export default CollectionResult;