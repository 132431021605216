export default function (globalState) {
    const state = globalState.associationsMembers;

    const getAssociationsMembers = () => state.associationsMembers;
    const getAssociationsMember = () => state.associationsMember;
    const getInvitesResults = () => state.invitesResults;
    const getFetchStatus = () => state.fetchStatus;
    const getFetchAssociationsMemberStatus = () => state.fetchAssociationsMemberStatus;
    const getAssociationsMembersNextStatus = () => state.fetchStatusNext;
    const getAssociationsMembersPrevStatus = () => state.fetchStatusPrev;
    const getRemoveStatus = () => state.removeStatus;
    const getShowRemovingModalStatus = () => state.showRemovingModalStatus;
    const getPostStatus = () => state.postStatus;
    const getCancelStatus = () => state.cancelStatus;
    const getResendStatus = () => state.resendStatus;
    const getErrors = () => state.errorStatus;

    return {
        getAssociationsMembers,
        getAssociationsMember,
        getInvitesResults,
        getFetchStatus,
        getFetchAssociationsMemberStatus,
        getAssociationsMembersNextStatus,
        getAssociationsMembersPrevStatus,
        getRemoveStatus,
        getShowRemovingModalStatus,
        getPostStatus,
        getCancelStatus,
        getResendStatus,
        getErrors
    };
}
