import axios from 'axios';
import config from '../config';

export const fetchTests = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/tests/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const updateTestsIndex = (data) => {
    return axios.request({
        url: config.API_URL + 'api/tests/',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const createTest = (data) => {
    return axios.request({
        url: config.API_URL + '/api/tests/put',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const updateTest = (data) => {
    return axios.request({
        url: config.API_URL + 'api/tests?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const fetchTestQuestions = (data) => {
    return axios.request({
        url: config.API_URL + 'api/questions',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchStudentsAnswers = (data) => {
    return axios.request({
        url: config.API_URL + 'api/studentTestAnswers/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const addQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/questions',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveQuestion = (data) => {
    return axios.request({
        url: config.API_URL + '/api/questions?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteQuestion = (data) => {
    return axios.request({
        url: config.API_URL + '/api/questions',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};

export const addAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/answers',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveAnswer = (data) => {
    return axios.request({
        url: config.API_URL + '/api/answers?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteAnswer = (data) => {
    return axios.request({
        url: config.API_URL + '/api/answers',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};
