import axios from 'axios';
import config from '../config';

export const fetchAllCoursesByProvider = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providers/' + data.provider + '/courses/getallbyprovider',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchCoursesByName = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/getbyname',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchCourses = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const updateCourse = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/edit',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const updateFeatureCourse = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/feature?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const updatePublishCourse = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/library/publish?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const updateCEBCourseCode = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const createCourse = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const disableCEBrokerIntegration = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courses/cleancebcourses?providerId=' + data.id,
        method: 'POST',
        withCredentials: true
    });
};

export const deleteContent = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courses',
        method: 'DELETE',
        data: data,
        withCredentials: true
    });
};

export const cropCourseImage = (data) => {
    return axios.request({
        url: config.API_URL + 'api/images',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const fetchByLicensee = (url) => {
    return axios.request({
        url: config.API_URL + url,
        method: 'GET',
        withCredentials: true
    });
};
