export default function (globalState) {
    const state = globalState.revenue;

    const getRevenue = () => state.revenue;
    const getFetchStatus = () => state.fetchStatus;
    const getErrors = () => state.errorStatus;

    return {
        getRevenue,
        getFetchStatus,
        getErrors
    };
}
