import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/rosters';
import at from '../actions/types';

export default function* fetchSelectedTransaction(data) {
    try {
        const result = yield call(api.fetchTransaction, data);
        yield put({ type: at.FETCH_TRANSACTION_DETAILS_SUCCESS, transaction: result.data });
    } catch (error) {
        yield put({ type: at.FETCH_TRANSACTION_DETAILS_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.FETCH_TRANSACTION_DETAILS_REQUEST
        });
    }
}
