import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import course from './course';
import test from './test';
import quiz from './quiz';
import contentPage from './contentPage';
import survey from './survey';

export default function* root() {
    yield takeLatest(at.FETCH_COURSE_PAGES_REQUEST, course.fetchCoursePages);
    yield takeLatest(at.UPDATE_COURSE_CONTENT_REQUEST, course.updateCourseContent);
    yield takeLatest(at.UPDATE_PAGE_INDEXES_REQUEST, course.updatePagesIndexes);
    yield takeLatest(at.DELETE_CONTENT_REQUEST, course.deleteContent);
    yield takeLatest(at.CREATE_TEST_REQUEST, test.createTest);
    yield takeLatest(at.UPDATE_TEST_REQUEST, test.updateTest);
    yield takeLatest(at.CREATE_QUIZ_REQUEST, quiz.createQuiz);
    yield takeLatest(at.UPDATE_QUIZ_REQUEST, quiz.updateQuiz);
    yield takeLatest(at.CREATE_CONTENT_PAGE_REQUEST, contentPage.createContentPage);
    yield takeLatest(at.UPDATE_CONTENT_PAGE_REQUEST, contentPage.updateContentPage);
    yield takeLatest(at.CREATE_SURVEY_REQUEST, survey.createSurvey);
    yield takeLatest(at.UPDATE_SURVEY_REQUEST, survey.updateSurvey);
    yield takeLatest(at.UPDATE_CONTENT_PAGE_TIMER_REQUEST, contentPage.updateContentPageTimer);
}
