const env = process.env.REACT_APP_ENV || 'development';

// Production and shared settings
let config = {
  API_URL: 'https://dev.mytablemesa.com/',
  DOMAIN: '.dev.mytablemesa.com',
  INTERCOM_ENABLED: false,
  INTERCOM_APPID: 'pnskmt74',
  VIDEOS: {
    API_URL: 'http://127.1.1.1:8000',
    OUTPUT_DOMAIN: 'https://d36fyevsyg3dnh.cloudfront.net/'
  },
  MAILCHIMP_ENABLED: false,
  LOGROCKET_ENABLED: false,
  LOGROCKET_APPID: 'brspcl/my-tablemesa-test'
};

// Override settings according to the environment
switch (env) {
  case 'test':
    config.API_URL = 'https://test.mytablemesa.com/';
    config.DOMAIN = '.test.mytablemesa.com';
    config.VIDEOS.API_URL = 'https://videos.test.mytablemesa.com';
    config.LOGROCKET_ENABLED = true;
    break;
  case 'next':
    config.API_URL = 'https://next.mytablemesa.com/';
    config.DOMAIN = '.next.mytablemesa.com';
    config.VIDEOS.API_URL = 'https://videos.test.mytablemesa.com';
    config.LOGROCKET_ENABLED = true;
    break;
  case 'production':
    config.API_URL = 'https://www.mytablemesa.com/';
    config.DOMAIN = '.mytablemesa.com';
    config.INTERCOM_ENABLED = true;
    config.VIDEOS.API_URL = 'https://api.videos.mytablemesa.com';
    config.VIDEOS.OUTPUT_DOMAIN = 'https://d2z1calppl4rb9.cloudfront.net/';
    config.MAILCHIMP_ENABLED = true;
    config.LOGROCKET_ENABLED = true;
    config.LOGROCKET_APPID = 'k3711z/tablemesa';
    break;
  default:
}

export default config; 