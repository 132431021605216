import { call, put } from 'redux-saga/effects';
import * as apiQuizzes from '../../../api/quizzes.js';

import at from '../actions/types';

function* createQuiz({ data }) {
    try {
        const result = yield call(apiQuizzes.createQuiz, data);
        yield put({ type: at.CREATE_QUIZ_SUCCESS, result });
    } catch (error) {
        yield put({ type: at.CREATE_QUIZ_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.CREATE_QUIZ_REQUEST
        });
    }
}

function* updateQuiz({ data }) {
    try {
        yield call(apiQuizzes.updateQuiz, data);
        yield put({ type: at.UPDATE_QUIZ_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_QUIZ_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_QUIZ_REQUEST
        });
    }
}

export default { createQuiz, updateQuiz };
