import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const bankAccount = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_REQUEST:
        case at.FETCH_FAILURE: {
            return defaultState;
        }
        case at.FETCH_SUCCESS: {
            const { account } = action;
            return account;
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_REQUEST: {
            return 'loading';
        }
        case at.FETCH_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const createStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_REQUEST: {
            return 'creating';
        }
        case at.CREATE_SUCCESS: {
            return 'created';
        }
        case at.CREATE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_STATUS: {
            return 'notCreated'
        }
        default:
            return state;
    }
};

export const updateStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = [], action) => {
    switch (action.type) {
        case at.UPDATE_REQUEST: {
            return [];
        }
        case at.REQUEST_FAILURE: {
            return action;
        }
        default:
            return state;
    }
};

export default combineReducers({ bankAccount, fetchStatus, createStatus, updateStatus, errorStatus });
