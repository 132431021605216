import axios from 'axios';
import config from '../config';

export const fetchCourseContentPages = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courseContentPages/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const updateCourseContentPageIndex = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courseContentPages/',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const updateContentPageTimer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courseContentPages/timer',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const createContentPage = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courseContentPages/',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const updateContentPage = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courseContentPages?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};


