import axios from 'axios';
import config from '../config';

export const fetchSurveys = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/surveys/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const updateSurveysIndex = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveys/',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const createSurvey = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveys',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const updateSurvey = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveys?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const fetchSurveyQuestions = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyquestions/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchStudentsAnswers = (data) => {
    return axios.request({
        url: config.API_URL + 'api/studentSurveyAnswers/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchExportDetails = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveys/export',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const addQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyquestions',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyquestions?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyquestions',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};

export const addAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyanswers',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyanswers?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyanswers',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};

export const deleteAllAnswers = (data) => {
    return axios.request({
        url: config.API_URL + 'api/surveyanswers?questionId=' + data.questionId,
        method: 'DELETE',
        withCredentials: true
    });
};
