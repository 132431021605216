import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/coupons';
import at from '../actions/types';

export default function* create(data) {
  try {
    const result = yield call(api.createCoupon, data);
    yield put({ type: at.CREATE_SUCCESS, newCoupon: result.data });
    yield put({ type: at.TOGGLE_COUPON_MODAL, showModal: false });
  } catch (error) {
    yield put({ type: at.CREATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.CREATE_REQUEST
    });
  }
}
