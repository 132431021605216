export default {
    FETCH_REQUEST: 'coupons/FETCH_REQUEST',
    FETCH_SUCCESS: 'coupons/FETCH_SUCCESS',
    FETCH_FAILURE: 'coupons/FETCH_FAILURE',

    DELETE_REQUEST: 'coupons/DELETE_REQUEST',
    DELETE_SUCCESS: 'coupons/DELETE_SUCCESS',
    DELETE_FAILURE: 'coupons/DELETE_FAILURE',

    FETCH_COUPON_REQUEST: 'coupons/FETCH_COUPON_REQUEST',
    FETCH_COUPON_SUCCESS: 'coupons/FETCH_COUPON_SUCCESS',
    FETCH_COUPON_FAILURE: 'coupons/FETCH_COUPON_FAILURE',

    CREATE_REQUEST: 'coupons/CREATE_REQUEST',
    CREATE_SUCCESS: 'coupons/CREATE_SUCCESS',
    CREATE_FAILURE: 'coupons/CREATE_FAILURE',
    TOGGLE_COUPON_MODAL: 'coupons/TOGGLE_COUPON_MODAL',

    CLEAR_DELETE_STATUS: 'coupons/CLEAR_DELETE_STATUS',

    FETCH_COUPON_PERFORMANCE_REQUEST: 'coupon/FETCH_COUPON_PERFORMANCE_REQUEST',
    FETCH_COUPON_PERFORMANCE_SUCCESS: 'coupon/FETCH_COUPON_PERFORMANCE_SUCCESS',
    FETCH_COUPON_PERFORMANCE_FAILURE: 'coupon/FETCH_COUPON_PERFORMANCE_FAILURE',

    REQUEST_FAILURE: 'coupons/REQUEST_FAILURE',

    UPDATE_REQUEST: 'coupons/UPDATE_REQUEST',
    UPDATE_SUCCESS: 'coupons/UPDATE_SUCCESS',
    UPDATE_FAILURE: 'coupons/UPDATE_FAILURE'
};