import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/licensees';
import at from '../actions/types';

export default function* fetchNext(url) {
  try {
    const result = yield call(api.fetchNextLicensees, url);
    yield put({ type: at.FETCH_NEXT_SUCCESS, licensees: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_NEXT_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data: url,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_NEXT_REQUEST
    });
  }
}
