import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/coupons';
import at from '../actions/types';

export default function* deleteCoupon(data) {
  try {
    const result = yield call(api.deleteCoupon, data);
    yield put({ type: at.DELETE_SUCCESS, couponId: result.data });
  } catch (error) {
    yield put({ type: at.DELETE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.DELETE_REQUEST
    });
  }
}