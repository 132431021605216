import axios from 'axios';
import config from '../config';

export const fetchLoggedInUser = () => {
  return axios.request({
    url: config.API_URL + 'api/users',
    method: 'GET',
    withCredentials: true
  });
};

export const updateLoggedInUser = ({ data }) => {
  return axios.request({
    url: config.API_URL + 'account/update?id=' + data.id,
    method: 'POST',
    //headers: { 'X-HTTP-Method-Override': 'PATCH' },
    data: data,
    withCredentials: true
  });
};

export const updateUser = ({ data }) => {
  return axios.request({
    url: config.API_URL + 'api/users?id=' + data.id,
    method: 'POST',
    data: data,
    withCredentials: true
  });
};

export const updateUserRole = ({ data }) => {
  return axios.request({
    url: config.API_URL + 'api/users',
    method: 'POST',
    params: data,
    withCredentials: true
  });
};

export const changePassword = (data) => {
  return axios.request({
    url: config.API_URL + 'api/users',
    method: 'POST',
    data: data,
    withCredentials: true
  });
};

export const resetPassword = (data) => {
  return axios.request({
    url: config.API_URL + 'api/users/forgotpassword',
    method: 'POST',
    params: data,
    withCredentials: true
  });
};

export const refreshLogoClaims = (data) => {
  return axios.request({
    url: config.API_URL + 'api/users/refreshUserProviderLogo',
    method: 'POST',
    params: data,
    withCredentials: true
  });
};

export const refreshClaims = (data) => {
  return axios.request({
    url: config.API_URL + 'account/claims/refresh',
    method: 'POST',
    params: data,
    withCredentials: true
  });
};

export const convertToAssociations = ({ data }) => {
  return axios.request({
    url: config.API_URL + 'api/users/updateproviderrole',
    method: 'PATCH',
    params: data,
    withCredentials: true
  });
};

export const refreshAdminClaims = (data) => {
  return axios.request({
    url: config.API_URL + 'api/users',
    method: 'POST',
    params: data,
    withCredentials: true
  });
};