import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/certificateConfig';
import at from '../actions/types';

function* update(data) {
    try {
        yield call(api.saveConfigurationCertificate, data);
        yield put({ type: at.UPDATE_CERTIFICATE_CONFIG_SUCCESS, config: data });
    } catch (error) {
        yield put({ type: at.UPDATE_CERTIFICATE_CONFIG_FAILURE });
        yield put({
            type: at.UPDATE_CERTIFICATE_CONFIG_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_CERTIFICATE_CONFIG_REQUEST
        });
    }
}

export default { update };