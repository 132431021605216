import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = [];

export const courses = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_FAILURE: {
      return defaultState;
    }
    case at.FETCH_SUCCESS: {
      const items = action.courses.items || [];
      return items;
    }
    case at.UPDATE_FEATURE_SUCCESS: {
      const { data } = action;
      const course = { isProviderFeatured: false };
      return state.map(c => {
        if (c.id === data.id) {
          return { ...c, ...action.data };
        }
        return { ...c, ...course };
      });
    }
    case at.UPDATE_PUBLISH_SUCCESS: {
      const { data } = action;
      return state.map(c => {
        if (c.id === data.id) {
          return { ...c, isActive: data.isActive, isComplete: data.isComplete };
        }
        return c;
      });
    }
    case at.UPDATE_PUBLISH_FAILURE: {
      return state;
    }
    case at.CREATE_COURSE_SUCCESS: {
      const { course } = action;
      const data = { ...state, course };
      const newState = Object.keys(data).map((k) => data[k]);
      return newState;
    }
    case at.UPDATE_SUCCESS:
    case at.UPDATE_CEBCODE_SUCCESS: {
      const { data } = action;
      return state.map(c => {
        if (c.id === data.id) {
          return { ...c, ...data };
        }
        return c;
      })
    }
    case at.CLEAN_CEBCODE_COURSES_SUCCESS: {
      return state.map(c => {
        return { ...c, cebCode: null };
      });
    }
    default:
      return state;
  }
};

export const course = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_COURSE_REQUEST:
    case at.FETCH_COURSE_SUCCESS: {
      const { course } = action;
      if (course) {
        return course;
      }
      return state;
    }
    case at.FETCH_COURSE_FAILURE: {
      return state;
    }
    case at.UPDATE_SUCCESS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case at.UPDATE_PUBLISH_COURSE_BUILDER_SUCCESS: {
      const { data } = action;
      return { ...state, isActive: data.isActive, isComplete: data.isComplete };
    }
    case at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_SUCCESS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case at.REFRESH_COURSE_IMAGE: {
      return { ...state, imageUrl: state.imageUrl + '?' + new Date().getTime() };
    }
    default:
      return state;
  }
};

export const published = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const items = action.courses.items || [];
      let actives = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].isActive) {
          actives++;
        }
      }
      return actives;
    }
    default:
      return state;
  }
};

export const unPublished = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const items = action.courses.items || [];
      let inactives = 0;
      for (let i = 0; i < items.length; i++) {
        if (!items[i].isActive && items[i].isComplete) {
          inactives++;
        }
      }
      return inactives;
    }
    default:
      return state;
  }
};

export const drafts = (state = 0, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const items = action.courses.items || [];
      let inComplete = 0;
      for (let i = 0; i < items.length; i++) {
        if (!items[i].isActive && !items[i].isComplete) {
          inComplete++;
        }
      }
      return inComplete;
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const fetchCourseStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_COURSE_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COURSE_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COURSE_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const createStatus = (state = 'notCreated', action) => {
  switch (action.type) {
    case at.CREATE_COURSE_REQUEST: {
      return 'creating';
    }
    case at.CREATE_COURSE_SUCCESS: {
      return 'created';
    }
    case at.CREATE_COURSE_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_CREATE_STATUS: {
      return 'notCreated';
    }
    default:
      return state;
  }
};

export const updateStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_UPDATE_STATUS: {
      return 'notUpdated';
    }
    default:
      return state;
  }
};

export const updateFeatureStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_FEATURE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_FEATURE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_FEATURE_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const updatePublishStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_PUBLISH_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_PUBLISH_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_PUBLISH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const updatePublishCourseBuilderStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_PUBLISH_COURSE_BUILDER_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_PUBLISH_COURSE_BUILDER_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_PUBLISH_COURSE_BUILDER_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_PUBLISH_COURSE_BUILDER_STATUS: {
      return 'notUpdated';
    }
    default:
      return state;
  }
};

export const updateCebCodeStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_CEBCODE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_CEBCODE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_CEBCODE_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_CEBCODE_STATUS: {
      return 'notUpdated';
    }
    default:
      return state;
  }
};


export const updateCebCodeIndividualCourseStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_UPDATE_CEBCODE_INDIVIDUAL_COURSE_STATUS: {
      return 'notUpdated';
    }
    default:
      return state;
  }
};

export const fetchStatusNext = (state = false, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { courses: { next } } = action;
      return next ? true : false;
    }
    default:
      return state;
  }
};

export const fetchStatusPrev = (state = false, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      const { courses: { previous } } = action;
      return previous ? true : false;
    }
    default:
      return state;
  }
};

export const errorStatus = (state = defaultState, action, request) => {
  switch (action.type) {
    case at.FETCH_REQUEST:
    case at.CREATE_COURSE_REQUEST:
    case at.UPDATE_REQUEST:
    case at.UPDATE_FEATURE_REQUEST:
    case at.UPDATE_PUBLISH_REQUEST:
    case at.UPDATE_CEBCODE_REQUEST: {
      return [];
    }
    case at.REQUEST_FAILURE: {
      return action;
    }
    default:
      return state;
  }
};

export const publishingErrors = (state = {}, action) => {
  switch (action.type) {
    case at.UPDATE_PUBLISH_COURSE_BUILDER_FAILURE: {
      const { modelState } = action;
      let overviewList = [];
      let settingsList = [];
      let testList = [];
      let quizList = [];
      let surveyList = [];
      let contentPagesList = [];
      const errorsKeys = Object.keys(modelState);

      errorsKeys.forEach((key, value) => {
        if (['name', 'description', 'objectives'].includes(key)) {
          overviewList.push({ [key]: modelState[key][0] });
        }
        if (['maximumCredits', 'state', 'certType', 'price', 'certificateConfigId', 'certificateTemplate'].includes(key)) {
          settingsList.push({ [key]: modelState[key][0] });
        }
        if (key.includes('test') || key === 'passPercentage') {
          testList.push({ [key]: modelState[key][0] });
        }
        if (key.includes('quiz')) {
          quizList.push({ [key]: modelState[key][0] });
        }
        if (key.includes('survey')) {
          surveyList.push({ [key]: modelState[key][0] });
        }
        if (key === 'contentPages') {
          contentPagesList.push({ [key]: modelState[key][0] });
        }
      });

      return {
        overview: overviewList,
        settings: settingsList,
        test: testList,
        quiz: quizList,
        survey: surveyList,
        contentPages: contentPagesList
      };
    }

    case at.CLEAR_PUBLISH_COURSE_BUILDER_ERRORS: {
      return {};
    }

    default:
      return state;
  }
};

export default combineReducers({ courses, fetchStatus, errorStatus, createStatus, fetchStatusNext, fetchStatusPrev, updateFeatureStatus, updatePublishStatus, updateCebCodeStatus, published, unPublished, drafts, course, fetchCourseStatus, updateStatus, updatePublishCourseBuilderStatus, publishingErrors, updateCebCodeIndividualCourseStatus });
