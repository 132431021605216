export default function (globalState) {
    const state = globalState.coupons;

    const getCoupons = () => state.coupons;
    const getFetchStatus = () => state.fetchStatus;
    const getSelectedCoupon = () => state.selectedCoupon;
    const getSelectedCouponStatus = () => state.fetchSelectedCouponStatus;
    const getCreateStatus = () => state.createStatus;
    const getDeleteStatus = () => state.deleteStatus;
    const getUpdateStatus = () => state.updateStatus;
    const getShowModalStatus = () => state.showCreatingModalStatus;
    const getProviderCouponNextStatus = () => state.fetchStatusNext;
    const getProviderCouponPrevStatus = () => state.fetchStatusPrev;
    const getCouponPerformance = () => state.couponPerformance;
    const getCouponPerformanceStatus = () => state.fetchCouponPerformanceStatus;
    const getErrors = () => state.errorStatus;

    return {
        getCoupons,
        getFetchStatus,
        getSelectedCoupon,
        getSelectedCouponStatus,
        getCreateStatus,
        getDeleteStatus,
        getUpdateStatus,
        getShowModalStatus,
        getProviderCouponNextStatus,
        getProviderCouponPrevStatus,
        getCouponPerformance,
        getCouponPerformanceStatus,
        getErrors
    };
}
