import React, { Component } from 'react';
import { Alert, Spinner } from 'emerald-ui';

class ComponentLoader extends Component {
    render() {
        const { isLoading, pastDelay, error } = this.props;

        return (
            <div>
                {isLoading && pastDelay && (
                    <div className="loader-spinner-content">
                        <Spinner size="lg" color="info" />
                    </div>
                )}
                {error && (
                    <Alert color="danger" className="m-t">There was a problem loading the page.</Alert>
                )}
            </div>
        );
    }
}

export default ComponentLoader;