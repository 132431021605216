import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = [];

export const providerApprovals = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_FAILURE: {
            return defaultState;
        }
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            const { approvals: { items = [] } } = action;
            return items;
        }
        case at.ADD_APPROVALS_SUCCESS: {
            const { approval } = action;

            const newApproval = { href: null, id: approval.data.id, courseId: 0, providerId: approval.data.providerId, name: approval.data.approvalName };
            const newAprovals = state.concat([newApproval]);

            return newAprovals;
        }
        default:
            return state;
    }
};

export const courseApprovals = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_BY_COURSE_FAILURE: {
            return defaultState;
        }
        case at.FETCH_BY_COURSE_SUCCESS: {
            const { approvals: { items = [] } } = action;
            return items;
        }
        case at.ADD_COURSE_APPROVALS_SUCCESS: {
            const { courseApprovals } = action;

            let newCourseApprovals = courseApprovals.map((cApproval) => {
                return { id: cApproval.id, providerId: cApproval.providerId, approvalId: cApproval.id, approval: { name: cApproval.approvalName } }
            });
            return newCourseApprovals;
        }
        case at.REMOVE_COURSE_APPROVALS_SUCCESS: {
            return [];
        }
        default:
            return state;
    }
};

export const fetchProviderApprovalsStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_PROVIDER_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchCourseApprovalsStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_COURSE_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_COURSE_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_COURSE_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const addCourseApprovalsStatus = (state = 'notSaved', action) => {
    switch (action.type) {
        case at.ADD_COURSE_APPROVALS_REQUEST: {
            return 'saving';
        }
        case at.ADD_COURSE_APPROVALS_SUCCESS: {
            return 'saved';
        }
        case at.ADD_COURSE_APPROVALS_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const removeCourseApprovalsStatus = (state = 'notRemoved', action) => {
    switch (action.type) {
        case at.REMOVE_COURSE_APPROVALS_REQUEST: {
            return 'removing';
        }
        case at.REMOVE_COURSE_APPROVALS_SUCCESS: {
            return 'removed';
        }
        case at.REMOVE_COURSE_APPROVALS_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};


export const errorStatus = (state = {}, action) => {
    let errors = {};

    if (action.type === at.REQUEST_FAILURE) {
        switch (action.requestFiled) {
            case (at.FETCH_BY_PROVIDER_REQUEST):
                errors.FETCH_BY_PROVIDER_REQUEST = action.message;
                break;
            case (at.FETCH_BY_COURSE_REQUEST):
                errors.FETCH_BY_COURSE_REQUEST = action.message;
                break;
            default:
                break;
        }
    }
    return errors;
};

export default combineReducers({ providerApprovals, courseApprovals, fetchProviderApprovalsStatus, fetchCourseApprovalsStatus, removeCourseApprovalsStatus, addCourseApprovalsStatus, errorStatus });