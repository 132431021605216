
import at from './types';

export const fetchCoursePages = function (dataCourseContentPage, testData, surveyData, shortTestData) {
    return { type: at.FETCH_COURSE_PAGES_REQUEST, dataCourseContentPage, testData, surveyData, shortTestData };
};

export const updateCourseContent = function (data) {
    return { type: at.UPDATE_COURSE_CONTENT_REQUEST, data };
};

export const updatePageIndexes = function (dataCourseContentPage, testsData, surveyData, shortTestData) {
    return { type: at.UPDATE_PAGE_INDEXES_REQUEST, dataCourseContentPage, testsData, surveyData, shortTestData };
};

export const clearUpdatePagesIndexesStatus = function (data) {
    return { type: at.UPDATE_PAGE_INDEXES_STATUS, data };
};

export const deleteContent = function (data) {
    return { type: at.DELETE_CONTENT_REQUEST, data };
};

export const clearDeleteContentStatus = function (data) {
    return { type: at.DELETE_CONTENT_STATUS, data };
};

export const createTest = function (data) {
    return { type: at.CREATE_TEST_REQUEST, data };
};

export const clearCreateTestStatus = function (data) {
    return { type: at.CLEAR_CREATE_TEST_STATUS, data };
};

export const clearUpdateTestStatus = function (data) {
    return { type: at.CLEAR_UPDATE_TEST_STATUS, data };
};

export const updateTest = function (data) {
    return { type: at.UPDATE_TEST_REQUEST, data };
};

export const createQuiz = function (data) {
    return { type: at.CREATE_QUIZ_REQUEST, data };
};

export const updateQuiz = function (data) {
    return { type: at.UPDATE_QUIZ_REQUEST, data };
};

export const clearCreateQuizStatus = function (data) {
    return { type: at.CLEAR_CREATE_QUIZ_STATUS, data };
};

export const clearUpdateQuizStatus = function (data) {
    return { type: at.CLEAR_UPDATE_QUIZ_STATUS, data };
};

export const createContentPage = function (data) {
    return { type: at.CREATE_CONTENT_PAGE_REQUEST, data };
};

export const updateContentPage = function (data) {
    return { type: at.UPDATE_CONTENT_PAGE_REQUEST, data };
};

export const clearCreateContentPageStatus = function (data) {
    return { type: at.CLEAR_CREATE_CONTENT_PAGE_STATUS, data };
};

export const clearUpdateContentPageStatus = function (data) {
    return { type: at.CLEAR_UPDATE_CONTENT_PAGE_STATUS, data };
};

export const createSurvey = function (data) {
    return { type: at.CREATE_SURVEY_REQUEST, data };
};

export const updateSurvey = function (data) {
    return { type: at.UPDATE_SURVEY_REQUEST, data };
};

export const clearCreateSurveyStatus = function (data) {
    return { type: at.CLEAR_CREATE_SURVEY_STATUS, data };
};

export const clearUpdateSurveyStatus = function (data) {
    return { type: at.CLEAR_UPDATE_SURVEY_STATUS, data };
};

export const updateContentPageTimer = function (data) {
    return { type: at.UPDATE_CONTENT_PAGE_TIMER_REQUEST, data };
};

export const clearUpdateContentPageTimerStatus = function (data) {
    return { type: at.CLEAR_UPDATE_CONTENT_PAGE_TIMER_STATUS, data };
};
