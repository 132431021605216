import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/studentLicenses';
import at from '../actions/types';

export default function* updateStudentLicense({ data }) {
    try {
        const requestData = {
            licenseNumber: data.licenseNumber
        };
        yield call(api.updateLicenseNumber, { id: data.id, data: requestData });
        yield put({ type: at.UPDATE_LICENSE_SUCCESS, data: data });
        yield put({ type: at.TOGGLE_LICENSE_EDITING, isEditing: false });
    } catch (error) {
        yield put({ type: at.UPDATE_LICENSE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_LICENSE_REQUEST
        });
    }
}