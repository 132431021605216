import axios from 'axios';
import config from '../config';

export const fetchRostersActivity = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/rosters/activity',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchRostersTransactions = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/rosters/transactions',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchNextRosters = ({ url }) => {
    return axios.request({
        url: config.API_URL + url,
        method: 'GET',
        withCredentials: true
    });
};

export const fetchTransaction = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/rosters/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};
