import axios from 'axios';
import config from '../config';

export const fetchBySurvey = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/licensees/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchByTest = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/licensees/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchNextLicensees = ({ url }) => {
    return axios.request({
        url: config.API_URL + url,
        method: 'GET',
        withCredentials: true
    });
};

export const fetchById = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/licensees/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchByUserName = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/licensees/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};
