export default {
    FETCH_BY_PROVIDER_REQUEST: 'approvals/FETCH_BY_PROVIDER_REQUEST',
    FETCH_BY_PROVIDER_SUCCESS: 'approvals/FETCH_BY_PROVIDER_SUCCESS',
    FETCH_BY_PROVIDER_FAILURE: 'approvals/FETCH_BY_PROVIDER_FAILURE',

    FETCH_BY_COURSE_REQUEST: 'approvals/FETCH_BY_COURSE_REQUEST',
    FETCH_BY_COURSE_SUCCESS: 'approvals/FETCH_BY_COURSE_SUCCESS',
    FETCH_BY_COURSE_FAILURE: 'approvals/FETCH_BY_COURSE_FAILURE',

    /*SAVE_COURSE_APPROVALS_REQUEST: 'approvals/SAVE_COURSE_APPROVALS_REQUEST',
    SAVE_COURSE_APPROVALS_SUCCESS: 'approvals/SAVE_COURSE_APPROVALS_SUCCESS',
    SAVE_COURSE_APPROVALS_FAILURE: 'approvals/SAVE_COURSE_APPROVALS_FAILURE',*/

    REMOVE_COURSE_APPROVALS_REQUEST: 'approvals/REMOVE_COURSE_APPROVALS_REQUEST',
    REMOVE_COURSE_APPROVALS_SUCCESS: 'approvals/REMOVE_COURSE_APPROVALS_SUCCESS',
    REMOVE_COURSE_APPROVALS_FAILURE: 'approvals/REMOVE_COURSE_APPROVALS_FAILURE',

    ADD_COURSE_APPROVALS_REQUEST: 'approvals/ADD_COURSE_APPROVALS_REQUEST',
    ADD_COURSE_APPROVALS_SUCCESS: 'approvals/ADD_COURSE_APPROVALS_SUCCESS',
    ADD_COURSE_APPROVALS_FAILURE: 'approvals/ADD_COURSE_APPROVALS_FAILURE',

    ADD_APPROVALS_REQUEST: 'approvals/ADD_APPROVALS_REQUEST',
    ADD_APPROVALS_SUCCESS: 'approvals/ADD_APPROVALS_SUCCESS',
    ADD_APPROVALS_FAILURE: 'approvals/ADD_APPROVALS_FAILURE',

    REQUEST_FAILURE: 'approvals/REQUEST_FAILURE'
};
