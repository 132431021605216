import at from './types';

export const fetch = function (data) {
    return { type: at.FETCH_REQUEST, data };
};

export const fetchSelectedCoupon = function (data) {
    return { type: at.FETCH_COUPON_REQUEST, data };
};

export const createCoupon = function (data) {
    return { type: at.CREATE_REQUEST, data };
};

export const deleteCoupon = function (data) {
    return { type: at.DELETE_REQUEST, data };
};

export const clearDeleteCouponStatus = function (data) {
    return { type: at.CLEAR_DELETE_STATUS, data };
};

export const toggleCouponModal = function (showModal) {
    return { type: at.TOGGLE_COUPON_MODAL, showModal };
};

export const updateCoupon = function (data) {
    return { type: at.UPDATE_REQUEST, data };
};

export const fetchCouponPerformance = function (data) {
    return { type: at.FETCH_COUPON_PERFORMANCE_REQUEST, data };
}
