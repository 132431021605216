import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/rosters';
import at from '../actions/types';

export default function* fetchTransactions(data) {
  try {
    const result = yield call(api.fetchRostersTransactions, data);
    yield put({ type: at.FETCH_TRANSACTIONS_SUCCESS, rosters: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_TRANSACTIONS_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_TRANSACTIONS_REQUEST
    });
  }
}
