import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';

export default function* fetchById(data) {
  try {
    const result = yield call(api.fetchProviderById, data);
    yield put({ type: at.FETCH_BY_ID_SUCCESS, provider: result.data });
    yield put({ type: at.REFRESH_LOGO });
  } catch (error) {
    yield put({ type: at.FETCH_BY_ID_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_BY_ID_REQUEST
    });
  }
}
