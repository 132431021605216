export default {
    FETCH_COURSE_PAGES_REQUEST: 'courseBuilder/FETCH_COURSE_PAGES_REQUEST',
    FETCH_COURSE_PAGES_SUCCESS: 'courseBuilder/FETCH_COURSE_PAGES_SUCCESS',
    FETCH_COURSE_PAGES_FAILURE: 'courseBuilder/FETCH_COURSE_PAGES_FAILURE',

    UPDATE_COURSE_CONTENT_REQUEST: 'courseBuilder/UPDATE_COURSE_CONTENT_REQUEST',
    UPDATE_COURSE_CONTENT_SUCCESS: 'courseBuilder/UPDATE_COURSE_CONTENT_SUCCESS',
    UPDATE_COURSE_CONTENT_FAILURE: 'courseBuilder/UPDATE_COURSE_CONTENT_FAILURE',

    UPDATE_PAGE_INDEXES_REQUEST: 'courseBuilder/UPDATE_PAGE_INDEXES_REQUEST',
    UPDATE_PAGE_INDEXES_SUCCESS: 'courseBuilder/UPDATE_PAGE_INDEXES_SUCCESS',
    UPDATE_PAGE_INDEXES_FAILURE: 'courseBuilder/UPDATE_PAGE_INDEXES_FAILURE',

    UPDATE_PAGE_INDEXES_STATUS: 'courseBuilder/UPDATE_PAGE_INDEXES_STATUS',

    DELETE_CONTENT_REQUEST: 'courseBuilder/DELETE_CONTENT_REQUEST',
    DELETE_CONTENT_SUCCESS: 'courseBuilder/DELETE_CONTENT_SUCCESS',
    DELETE_CONTENT_FAILURE: 'courseBuilder/DELETE_CONTENT_FAILURE',

    DELETE_CONTENT_STATUS: 'courseBuilder/DELETE_CONTENT_STATUS',

    UPDATE_TEST_REQUEST: 'courseBuilder/UPDATE_TEST_REQUEST',
    UPDATE_TEST_SUCCESS: 'courseBuilder/UPDATE_TEST_SUCCESS',
    UPDATE_TEST_FAILURE: 'courseBuilder/UPDATE_TEST_FAILURE',

    CLEAR_UPDATE_TEST_STATUS: 'courseBuilder/CLEAR_UPDATE_TEST_STATUS',

    CREATE_TEST_REQUEST: 'courseBuilder/CREATE_TEST_REQUEST',
    CREATE_TEST_SUCCESS: 'courseBuilder/CREATE_TEST_SUCCESS',
    CREATE_TEST_FAILURE: 'courseBuilder/CREATE_TEST_FAILURE',

    CLEAR_CREATE_TEST_STATUS: 'courseBuilder/CLEAR_CREATE_TEST_STATUS',

    CREATE_QUIZ_REQUEST: 'courseBuilder/CREATE_QUIZ_REQUEST',
    CREATE_QUIZ_SUCCESS: 'courseBuilder/CREATE_QUIZ_SUCCESS',
    CREATE_QUIZ_FAILURE: 'courseBuilder/CREATE_QUIZ_FAILURE',

    CLEAR_CREATE_QUIZ_STATUS: 'courseBuilder/CLEAR_CREATE_QUIZ_STATUS',

    UPDATE_QUIZ_REQUEST: 'courseBuilder/UPDATE_QUIZ_REQUEST',
    UPDATE_QUIZ_SUCCESS: 'courseBuilder/UPDATE_QUIZ_SUCCESS',
    UPDATE_QUIZ_FAILURE: 'courseBuilder/UPDATE_QUIZ_FAILURE',

    CLEAR_UPDATE_QUIZ_STATUS: 'courseBuilder/CLEAR_UPDATE_QUIZ_STATUS',

    CREATE_CONTENT_PAGE_REQUEST: 'courseBuilder/CREATE_CONTENT_PAGE_REQUEST',
    CREATE_CONTENT_PAGE_SUCCESS: 'courseBuilder/CREATE_CONTENT_PAGE_SUCCESS',
    CREATE_CONTENT_PAGE_FAILURE: 'courseBuilder/CREATE_CONTENT_PAGE_FAILURE',

    CLEAR_CREATE_CONTENT_PAGE_STATUS: 'courseBuilder/CLEAR_CREATE_CONTENT_PAGE_STATUS',

    UPDATE_CONTENT_PAGE_REQUEST: 'courseBuilder/UPDATE_CONTENT_PAGE_REQUEST',
    UPDATE_CONTENT_PAGE_SUCCESS: 'courseBuilder/UPDATE_CONTENT_PAGE_SUCCESS',
    UPDATE_CONTENT_PAGE_FAILURE: 'courseBuilder/UPDATE_CONTENT_PAGE_FAILURE',

    CLEAR_UPDATE_CONTENT_PAGE_STATUS: 'courseBuilder/CLEAR_UPDATE_CONTENT_PAGE_STATUS',

    UPDATE_CONTENT_PAGE_TIMER_REQUEST: 'courseBuilder/UPDATE_CONTENT_PAGE_TIMER_REQUEST',
    UPDATE_CONTENT_PAGE_TIMER_SUCCESS: 'courseBuilder/UPDATE_CONTENT_PAGE_TIMER_SUCCESS',
    UPDATE_CONTENT_PAGE_TIMER_FAILURE: 'courseBuilder/UPDATE_CONTENT_PAGE_TIMER_FAILURE',

    CLEAR_UPDATE_CONTENT_PAGE_TIMER_STATUS: 'courseBuilder/CLEAR_UPDATE_CONTENT_PAGE_TIMER_STATUS',

    CREATE_SURVEY_REQUEST: 'courseBuilder/CREATE_SURVEY_REQUEST',
    CREATE_SURVEY_SUCCESS: 'courseBuilder/CREATE_SURVEY_SUCCESS',
    CREATE_SURVEY_FAILURE: 'courseBuilder/CREATE_SURVEY_FAILURE',

    CLEAR_CREATE_SURVEY_STATUS: 'courseBuilder/CLEAR_CREATE_SURVEY_STATUS',

    UPDATE_SURVEY_REQUEST: 'courseBuilder/UPDATE_SURVEY_REQUEST',
    UPDATE_SURVEY_SUCCESS: 'courseBuilder/UPDATE_SURVEY_SUCCESS',
    UPDATE_SURVEY_FAILURE: 'courseBuilder/UPDATE_SURVEY_FAILURE',

    CLEAR_UPDATE_SURVEY_STATUS: 'courseBuilder/CLEAR_UPDATE_SURVEY_STATUS',

    REQUEST_FAILURE: 'courseBuilder/REQUEST_FAILURE'
};
