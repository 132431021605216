import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import { fetchByAssociation, fetchById } from './fetch';
import remove from './remove';
import { postMemberInvites, cancelInvite, resendInvite } from './post';

export default function* root() {
    yield takeLatest(at.FETCH_BY_ASSOCIATION_REQUEST, fetchByAssociation);
    yield takeLatest(at.POST_MEMBER_INVITES_REQUEST, postMemberInvites);
    yield takeLatest(at.CANCEL_INVITE_REQUEST, cancelInvite);
    yield takeLatest(at.RESEND_INVITE_REQUEST, resendInvite);
	yield takeLatest(at.REMOVE_REQUEST, remove);
    yield takeLatest(at.FETCH_MEMBER_BY_ID_REQUEST, fetchById);
}
