export default function (globalState) {
    const state = globalState.approvals;

    const getProviderApprovals = () => state.providerApprovals;
    const getCourseApprovals = () => state.courseApprovals;
    const getFetchProviderApprovalsStatus = () => state.fetchProviderApprovalsStatus;
    const getFetchCourseApprovalsStatus = () => state.fetchCourseApprovalsStatus;
    const getRemoveCourseApprovalsStatus = () => state.removeCourseApprovalsStatus;
    const getAddCourseApprovalsStatus = () => state.addCourseApprovalsStatus;
    const getAddApprovalsStatus = () => state.addApprovalsStatus;
    const getErrors = () => state.errorStatus;

    return {
        getProviderApprovals,
        getCourseApprovals,
        getFetchProviderApprovalsStatus,
        getFetchCourseApprovalsStatus,
        getRemoveCourseApprovalsStatus,
        getAddCourseApprovalsStatus,
        getAddApprovalsStatus,
        getErrors
    };
}
