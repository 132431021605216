import { takeLatest, takeEvery } from 'redux-saga/effects';
import at from '../actions/types';
import course from './course';
import fetch from './fetch';

export default function* root() {
  yield takeLatest(at.UPDATE_FEATURE_REQUEST, course.updateFeatureCourse);
  yield takeLatest(at.UPDATE_PUBLISH_REQUEST, course.updatePublishCourse);
  yield takeLatest(at.CREATE_COURSE_REQUEST, course.createCourse);
  yield takeLatest(at.UPDATE_CEBCODE_REQUEST, course.updateCebCode);
  yield takeLatest(at.CLEAN_CEBCODE_COURSES_REQUEST, course.cleanCebCodes);
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeLatest(at.FETCH_COURSE_REQUEST, course.fetchCourse);
  yield takeLatest(at.UPDATE_REQUEST, course.updateCourse);
  yield takeLatest(at.UPDATE_PUBLISH_COURSE_BUILDER_REQUEST, course.updatePublishCourseBuilder);
  yield takeLatest(at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST, course.updateCebCodeIndividualCourse);
}
