import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/certificateConfig';
import at from '../actions/types';

function* fetch(data) {
  try {
    const result = yield call(api.fetch, data);
    yield put({ type: at.FETCH_CERTIFICATE_CONFIG_SUCCESS, config: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_CERTIFICATE_CONFIG_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_CERTIFICATE_CONFIG_REQUEST
    });
  }
}

export default { fetch };
