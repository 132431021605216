import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../actions/types';
import config from '../../../config';

export default function* fetch() {
  try {
    const result = yield call(api.fetchLoggedInUser);
    yield put({ type: at.FETCH_SUCCESS, user: result.data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      var url = config.API_URL + 'account/login';
      if (window.location.pathname && window.location.pathname !== '/') {
        url = window.location.pathname === '/' ? url : url + '?ReturnUrl=' + encodeURIComponent(window.location.pathname);
      }
      window.location.href = url;
      return false;
    }
    yield put({ type: at.FETCH_FAILURE });
  }
}
