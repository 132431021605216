import { combineReducers } from 'redux';
import at from '../actions/types';
import collectionResult from '../../../helpers/CollectionResult';

const defaultState = collectionResult;

export const activity = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_ACTIVITY_REQUEST:
    case at.FETCH_ACTIVITY_FAILURE: {
      return defaultState;
    }
    case at.FETCH_ACTIVITY_SUCCESS: {
      const { rosters } = action;
      return rosters;
    }

    case at.FETCH_NEXT_SUCCESS: {
      const { rosters } = action;
      rosters.items = state.items.concat(rosters.items);
      return rosters;
    }
    case at.FETCH_NEXT_FAILURE: {
      return state;
    }

    case at.REPORT_LICENSE_CEB_SUCCESS:
    case at.REPORT_LICENSE_CEB_FAILURE: {
      const { studentLicense } = action;
      var reportedLicense = {};

      const rosterParent = state.items.find(roster => {
        return roster.studentId === studentLicense.studentId;
      });
      if (rosterParent) {
        reportedLicense = rosterParent.studentLicenses.find(item => {
          return item.licenseId === studentLicense.licenseId;
        });
        if (reportedLicense) {
          reportedLicense.cebAttendeeId = studentLicense.cebAttendeeId;
          reportedLicense.cebAttendeeError = studentLicense.cebAttendeeError;
        }
      }

      return state;
    }

    case at.UPDATE_LICENSE_SUCCESS: {
      const { data } = action;
      var updatedLicense = {};

      const rosterParent = state.items.find(roster => {
        return roster.studentId === data.studentId;
      });
      if (rosterParent) {
        updatedLicense = rosterParent.studentLicenses.find(item => {
          return item.id === data.id;
        });
        if (updatedLicense) {
          updatedLicense.licenseNumber = data.licenseNumber;
        }
      }

      return state;
    }
    case at.UPDATE_LICENSE_REQUEST: {
      return state;
    }    
    default:
      return state;
  }
};

export const transactions = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_TRANSACTIONS_REQUEST:
    case at.FETCH_TRANSACTIONS_FAILURE: {
      return defaultState;
    }
    case at.FETCH_TRANSACTIONS_SUCCESS: {
      const { rosters } = action;
      return rosters;
    }
    default:
      return state;
  }
};

export const selectedTransaction = (state = {}, action) => {
  switch (action.type) {
      case at.FETCH_TRANSACTION_DETAILS_REQUEST:
      case at.FETCH_TRANSACTION_DETAILS_SUCCESS: {
          const { transaction } = action;
          if (transaction) {
              return transaction;
          }
          return state;
      }
      case at.FETCH_TRANSACTION_DETAILS_FAILURE: {
          return state;
      }
      default:
          return state;
  }
};

export const selectedTransactionStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_TRANSACTION_DETAILS_REQUEST: {
      return 'loading';
    }
    case at.FETCH_TRANSACTION_DETAILS_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_TRANSACTION_DETAILS_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const fetchActivityStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_ACTIVITY_REQUEST: {
      return 'loading';
    }
    case at.FETCH_ACTIVITY_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_ACTIVITY_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const fetchTransactionsStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_TRANSACTIONS_REQUEST: {
      return 'loading';
    }
    case at.FETCH_TRANSACTIONS_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_TRANSACTIONS_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const fetchNextStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_NEXT_REQUEST: {
      return 'loading';
    }
    case at.FETCH_NEXT_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_NEXT_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const reportCEBStatus = (state = 'notReported', action) => {
  switch (action.type) {
    case at.REPORT_LICENSE_CEB_REQUEST: {
      return 'reporting';
    }
    case at.REPORT_LICENSE_CEB_SUCCESS: {
      return 'reported';
    }
    case at.REPORT_LICENSE_CEB_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const updatingLicenseStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_LICENSE_REQUEST: {
      return 'updating';
    }
    case at.UPDATE_LICENSE_SUCCESS: {
      return 'updated';
    }
    case at.UPDATE_LICENSE_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const editingLicenseStatus = (state = false, action) => {
  switch (action.type) {
    case at.TOGGLE_LICENSE_EDITING: {
      const { isEditing } = action;
      return isEditing;
    }
    default:
      return state;
  }
};

export const errorStatus = (state = defaultState, action) => {
  switch (action.type) {
    case at.FETCH_ACTIVITY_REQUEST:
    case at.FETCH_TRANSACTIONS_REQUEST:
    case at.FETCH_NEXT_REQUEST:
    case at.REPORT_LICENSE_CEB_REQUEST:
    case at.UPDATE_LICENSE_REQUEST: {
      return [];
    }
    case at.REQUEST_FAILURE: {
      return action;
    }
    default:
      return state;
  }
};

export default combineReducers({ activity, transactions, fetchActivityStatus, fetchTransactionsStatus, fetchNextStatus, reportCEBStatus, updatingLicenseStatus, editingLicenseStatus, errorStatus, selectedTransaction, selectedTransactionStatus });
