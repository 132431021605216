import axios from 'axios';
import config from '../config';

export const fetchRevenueByYear = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/revenue/year',
        method: 'GET',
        params: data,
        withCredentials: true
    });
}

export const fetchRevenueByMonth = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/revenue/month',
        method: 'GET',
        params: data,
        withCredentials: true
    });
}