export default function(globalState) {
  const state = globalState.rosters;

  const getActivity = () => state.activity;
  const getTransactions = () => state.transactions;
  const getFetchActivityStatus = () => state.fetchActivityStatus;
  const getFetchTransactionsStatus = () => state.fetchTransactionsStatus;
  const getFetchNextStatus = () => state.fetchNextStatus;
  const getReportStatus = () => state.reportCEBStatus;
  const getUpdateLicenseStatus = () => state.updatingLicenseStatus;
  const getLicenseEditingStatus = () => state.editingLicenseStatus;
  const getSelectedTransaction = () => state.selectedTransaction;
  const getSelectedTransactionStatus = () => state.selectedTransactionStatus;
  const getErrors = () => state.errorStatus;

  return {
    getActivity,
    getTransactions,
    getFetchActivityStatus,
    getFetchTransactionsStatus,
    getFetchNextStatus,
    getReportStatus,
    getUpdateLicenseStatus,
    getLicenseEditingStatus,
    getSelectedTransaction,
    getSelectedTransactionStatus,
    getErrors
  };
}
