import at from './types';

export const fetch = function (data) {
    return { type: at.FETCH_CERTIFICATE_CONFIG_REQUEST, data };
};

export const update = function (data) {
    return { type: at.UPDATE_CERTIFICATE_CONFIG_REQUEST, data };
};

export const clearUpdateStatus = function (data) {
    return { type: at.CLEAR_UPDATE_CERTIFICATE_CONFIG_STATUS, data };
};

