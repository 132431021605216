import axios from 'axios';
import config from '../config';

export const fetchAccount = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/managedaccounts?id=' + data.stripeAccountId,
        method: 'GET',
        withCredentials: true
    });
};

export const createAccount = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/managedaccounts/full?providerId=' + data.providerId,
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const updateAccount = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/managedaccounts/full?providerId=' + data.providerId,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};