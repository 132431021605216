export default function (globalState) {
    const state = globalState.bankAccount;

    const getBankAccount = () => state.bankAccount;
    const getFetchStatus = () => state.fetchStatus;
    const getCreateStatus = () => state.createStatus;
    const getUpdateStatus = () => state.updateStatus;
    const getErrors = () => state.errorStatus;

    return {
        getBankAccount,
        getFetchStatus,
        getCreateStatus,
        getUpdateStatus,
        getErrors
    };
}
