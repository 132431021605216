import { fork } from 'redux-saga/effects';
import { rootSaga as loggedInUser } from './loggedInUser';
import { rootSaga as rosters } from './rosters';
import { rootSaga as revenue } from './revenue';
import { rootSaga as courses } from './courses';
import { rootSaga as providers } from './providers';
import { rootSaga as coupons } from './coupons';
import { rootSaga as courseCoupon } from './courseCoupon';
import { rootSaga as states } from './states';
import { rootSaga as bankAccount } from './bankAccount';
import { rootSaga as CEBCourses } from './CEBCourses';
import { rootSaga as courseBuilder } from './courseBuilder';
import { rootSaga as certificateConfig } from './certificateConfig';
import { rootSaga as approvals } from './approvals';
import { rootSaga as students } from './students';
import { rootSaga as surveys } from './surveys';
import { rootSaga as tests } from './tests';
import { rootSaga as licensees } from './licensees';
import { rootSaga as associationsMembers } from './associationsMembers';
import { rootSaga as providerUsers } from './providerUsers';

export default function* rootSaga() {
  yield [fork(loggedInUser), fork(rosters), fork(revenue), fork(courses), fork(providers), fork(coupons), fork(courseCoupon), fork(states), fork(bankAccount), fork(CEBCourses), fork(courseBuilder), fork(certificateConfig), fork(approvals), fork(students), fork(surveys), fork(tests), fork(licensees), fork(associationsMembers), fork(providerUsers)];
}



