export default function (globalState) {
    const state = globalState.licensees;

    const getLicensees = () => state.licensees;
    const getFetchStatus = () => state.fetchStatus;
    const getFetchNextStatus = () => state.fetchNextStatus;
    const getErrors = () => state.errorStatus;

    return {
        getLicensees,
        getFetchStatus,
        getFetchNextStatus,
        getErrors
    };
}
