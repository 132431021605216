import { takeLatest, takeEvery } from 'redux-saga/effects';
import at from '../actions/types';
import fetch from './fetch';
import create from './create';
import update from './update';

export default function* root() {
  yield takeEvery(at.FETCH_REQUEST, fetch);
  yield takeLatest(at.CREATE_REQUEST, create);
  yield takeLatest(at.UPDATE_REQUEST, update);
}

