import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/stripeAccount';
import at from '../actions/types';
import providerActionTypes from '../../providers/actions/types';

export default function* create(data) {
  try {
    const result = yield call(api.createAccount, data);
    yield put({ type: at.CREATE_SUCCESS, stripeAccountResult: result.data });
    yield put({ type: providerActionTypes.UPDATE_STRIPE_ACCOUNT_SUCCESS, stripeAccountResult: result.data });
  } catch (error) {
    yield put({ type: at.CREATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data && error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Oops! something went wrong!',
      requestFailed: at.CREATE_REQUEST
    });
  }
}
