import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';

export default function* saveProvider(data) {
    try {
        yield call(api.saveProvider, data);
        yield put({ type: at.SAVE_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.SAVE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.SAVE_REQUEST
        });
    }
}
