import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/studentLicenses';
import at from '../actions/types';

export default function* reportToCEBroker(data) {
  try {
    const result = yield call(api.reportToCEBroker, data);
    const studentLicense = result.data;
    studentLicense.studentId = data.data.studentId;
    studentLicense.licenseId = data.data.licenseId;
    
    yield put({ type: at.REPORT_LICENSE_CEB_SUCCESS, studentLicense: studentLicense });
  } catch (error) {
    console.log(error);
    yield put({
      type: at.REPORT_LICENSE_CEB_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!'
    });
  }
}
