import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/courseCoupon';
import at from '../actions/types';

export default function* save(data) {
  try {
    yield call(api.saveCoursesCoupon, data);
    yield put({ type: at.SAVE_COURSE_COUPON_SUCCESS });
    yield put({ type: at.TOGGLE_SAVE_COURSE_COUPON_STATUS, saved: true });
  } catch (error) {
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.SAVE_COURSE_COUPON
    });
  }
}
