import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import fetchById from './fetchById';
import save from './save';
import saveLogo from './saveLogo';
import saveBanner from './saveBanner';
import updateApprovals from './updateApprovals';
import updateDiscount from './updateDiscount';
import removeStripeAccount from './removeStripeAccount';
import saveCourseCompleteNotification from './saveCourseCompleteNotification';
import saveCourseStartedNotification from './saveCourseStartedNotification';
import toggleCEBIntegration from './toggleCEBIntegration';

export default function* root() {
  yield takeLatest(at.FETCH_BY_ID_REQUEST, fetchById);
  yield takeLatest(at.SAVE_REQUEST, save);
  yield takeLatest(at.SAVE_LOGO_REQUEST, saveLogo);
  yield takeLatest(at.SAVE_BANNER_REQUEST, saveBanner);
  yield takeLatest(at.UPDATE_APPROVALS_REQUEST, updateApprovals);
  yield takeLatest(at.UPDATE_DISCOUNT_REQUEST, updateDiscount);
  yield takeLatest(at.REMOVE_STRIPE_ACCOUNT_REQUEST, removeStripeAccount);
  yield takeLatest(at.SAVE_COURSE_COMPLETE_NOTIFICATION_REQUEST, saveCourseCompleteNotification);
  yield takeLatest(at.SAVE_COURSE_STARTED_NOTIFICATION_REQUEST, saveCourseStartedNotification);
  yield takeLatest(at.TOGGLE_CEBROKER_INTEGRATION_REQUEST, toggleCEBIntegration);
}
