import { call, put } from 'redux-saga/effects';
import * as apiTest from '../../../api/tests.js';

import at from '../actions/types';

function* createTest({ data }) {
    try {
        const result = yield call(apiTest.createTest, data);
        yield put({ type: at.CREATE_TEST_SUCCESS, result });
    } catch (error) {
        yield put({ type: at.CREATE_TEST_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.CREATE_TEST_REQUEST
        });
    }
}

function* updateTest({ data }) {
    try {
        yield call(apiTest.updateTest, data);
        yield put({ type: at.UPDATE_TEST_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_TEST_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_TEST_REQUEST
        });
    }
}

export default { createTest, updateTest }
