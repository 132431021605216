import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import fetchBySurvey from './fetchBySurvey';
import fetchByTest from './fetchByTest';
import fetchNext from './fetchNext';

export default function* root() {
    yield takeLatest(at.FETCH_BY_SURVEY_REQUEST, fetchBySurvey);
    yield takeLatest(at.FETCH_BY_TEST_REQUEST, fetchByTest);
    yield takeLatest(at.FETCH_NEXT_REQUEST, fetchNext);
}
