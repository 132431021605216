import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import fetchActivity from './fetchActivity';
import fetchTransactions from './fetchTransactions';
import fetchNext from './fetchNext';
import reportToCEBroker from './reportToCEBroker';
import updateStudentLicense from './updateStudentLicense';
import fetchSelectedTransaction from './fetchSelectedTransaction';

export default function* root() {
  yield takeLatest(at.FETCH_ACTIVITY_REQUEST, fetchActivity);
  yield takeLatest(at.FETCH_TRANSACTIONS_REQUEST, fetchTransactions);
  yield takeLatest(at.FETCH_NEXT_REQUEST, fetchNext);
  yield takeLatest(at.REPORT_LICENSE_CEB_REQUEST, reportToCEBroker);
  yield takeLatest(at.UPDATE_LICENSE_REQUEST, updateStudentLicense);
  yield takeLatest(at.FETCH_TRANSACTION_DETAILS_REQUEST, fetchSelectedTransaction);
}
