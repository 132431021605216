import axios from 'axios';
import config from '../config';

export const saveCoursesCoupon = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/coursecoupons/',
        method: 'POST',       
        params: data,
        withCredentials: true
    });
};
