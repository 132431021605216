import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';
import userAt from '../../loggedInUser/actions/types';

export default function* saveLogo(data) {
    try {
        const logoUrl = data.data.logoUrl;
        yield call(api.saveProviderLogo, data);
        yield put({ type: at.SAVE_LOGO_SUCCESS, logoUrl });
        yield put({ type: userAt.SET_USER_PROVIDER_LOGO, logoUrl });
    } catch (error) {
        yield put({ type: at.SAVE_LOGO_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.SAVE_LOGO_REQUEST
        });
    }
}
