import axios from 'axios';
import config from '../config';

export const fetchCEBproviderCourses = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/cebcourses?provider=' + data.id + "&onlyAssociates=" + data.onlyAssociates + "&q=" + data.q,
        method: 'GET',
        withCredentials: true
    });
};

export const fetchCEBproviderCourse = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/cebcourses?id=' + data.id + '&provider=' + data.provider,
        method: 'GET',
        withCredentials: true
    });
};
