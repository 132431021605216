import React from 'react';
import classNames from 'classnames'
import './AppFooter.css'

class AppFooter extends React.Component {
    render() {
        const { className, link } = this.props;
        return (
            <div className={classNames('app-footer-',className)}>
                <h3>Thoughts on the new dashboard?</h3>
                <a href={link}>Let us know</a>
            </div>
        )
    }
}


export default AppFooter;