export default function (globalState) {
    const state = globalState.courseBuilder;

    const getCoursePages = () => state.coursePages;
    const getCoursePagesStatus = () => state.fetchCoursePagesStatus;
    const getUpdateCourseContentStatus = () => state.updateCourseContentStatus;
    const getUpdatePagesIndexesStatus = () => state.updatePagesIndexesStatus;
    const getDeleteContentStatus = () => state.deleteContentStatus;
    const getErrors = () => state.errorStatus;
    const getUpdateTestStatus = () => state.updateTestStatus;
    const getCreateTestStatus = () => state.createTestStatus;
    const getCreateQuizStatus = () => state.createQuizStatus;
    const getUpdateQuizStatus = () => state.updateQuizStatus;
    const getCreateContentPageStatus = () => state.createContentPageStatus;
    const getUpdateContentPageStatus = () => state.updateContentPageStatus;
    const getCreateSurveyStatus = () => state.createSurveyStatus;
    const getUpdateSurveyStatus = () => state.updateSurveyStatus;
    const getCreatedStep = () => state.createdStep;
    const getUpdateContentPageTimerStatus = () => state.updateContentPageTimerStatus;

    return {
        getCoursePages,
        getCoursePagesStatus,
        getUpdateCourseContentStatus,
        getUpdatePagesIndexesStatus,
        getDeleteContentStatus,
        getCreateTestStatus,
        getUpdateTestStatus,
        getCreateQuizStatus,
        getUpdateQuizStatus,
        getCreateContentPageStatus,
        getUpdateContentPageStatus,
        getCreateSurveyStatus,
        getUpdateSurveyStatus,
        getCreatedStep,
        getUpdateContentPageTimerStatus,
        getErrors
    };
}
