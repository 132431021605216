import { combineReducers } from 'redux';
import at from '../actions/types';
import collectionResult from '../../../helpers/CollectionResult';

const defaultState = collectionResult;

export const providerUsers = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            const { providerUsers } = action;
            return providerUsers;
        }
        case at.UPDATE_BASIC_INFO_SUCCESS: {
            const { data } = action;
            const users = state.items.map(user => {
                if (user.applicationUserId === data.id) {
                    return { ...user, applicationUser: { ...user.applicationUser, firstName: data.firstName, lastName: data.lastName, userName: data.userName } };
                }
                return user;
            });
            return { ...state, items: users };
        }
        case at.UPDATE_ROLE_SUCCESS: {
            const { data } = action;
            const users = state.items.map(user => {
                if (user.applicationUserId === data.id) {
                    const newRole = { name: data.roleName };
                    return { ...user, applicationUser: { ...user.applicationUser, role: newRole } };
                }
                return user;
            });
            return { ...state, items: users };
        }
        default:
            return state;
    }
};

export const providerUser = (state = {}, action) => {
    switch (action.type) {
        case at.FETCH_BY_ID_SUCCESS: {
            const { providerUser } = action;
            return providerUser;
        }
        case at.UPDATE_BASIC_INFO_SUCCESS: {
            const { data } = action;
            return { ...state, applicationUser: { ...state.applicationUser, firstName: data.firstName, lastName: data.lastName, userName: data.userName } };
        }
        case at.UPDATE_ROLE_SUCCESS: {
            const { data } = action;
            const newRole = { name: data.roleName };
            return { ...state, applicationUser: { ...state.applicationUser, role: newRole } };
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_PROVIDER_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchByIdStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_ID_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_ID_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_ID_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchStatusNext = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            const { providerUsers: { next } } = action;
            return next ? true : false;
        }
        default:
            return state;
    }
};

export const fetchStatusPrev = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_BY_PROVIDER_SUCCESS: {
            const { providerUsers: { previous } } = action;
            return previous ? true : false;
        }
        default:
            return state;
    }
};

export const removeStatus = (state = 'notRemoved', action) => {
    switch (action.type) {
        case at.REMOVE_REQUEST: {
            return 'removing';
        }
        case at.REMOVE_SUCCESS: {
            return 'removed';
        }
        case at.REMOVE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_REMOVE_STATUS: {
            return 'notRemoved';
        }
        default:
            return state;
    }
};

export const showRemovingModalStatus = (state = false, action) => {
    switch (action.type) {
        case at.TOGGLE_REMOVE_MODAL: {
            const { showModal } = action;
            return showModal;
        }
        default:
            return state;
    }
};

export const createStatus = (state = 'notCreated', action) => {
    switch (action.type) {
        case at.CREATE_REQUEST: {
            return 'creating';
        }
        case at.CREATE_SUCCESS: {
            return 'created';
        }
        case at.CREATE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CREATE_STATUS: {
            return 'notCreated';
        }
        default:
            return state;
    }
};

export const updateBasicInfoStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_BASIC_INFO_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_BASIC_INFO_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_BASIC_INFO_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_INFO_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const updateRoleStatus = (state = 'notUpdated', action) => {
    switch (action.type) {
        case at.UPDATE_ROLE_REQUEST: {
            return 'updating';
        }
        case at.UPDATE_ROLE_SUCCESS: {
            return 'updated';
        }
        case at.UPDATE_ROLE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_UPDATE_ROLE_STATUS: {
            return 'notUpdated';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = {}, action) => {
    let errors = {};

    if (action.type === at.REQUEST_FAILURE) {
        switch (action.requestFailed) {
            case (at.FETCH_BY_PROVIDER_REQUEST):
                errors.FETCH_BY_PROVIDER_REQUEST = action.message;
                break;
            case (at.FETCH_BY_ID_REQUEST):
                errors.FETCH_BY_ID_REQUEST = action.message;
                break;
            case (at.CREATE_REQUEST):
                errors.CREATE_REQUEST = action.message;
                break;
            case (at.UPDATE_BASIC_INFO_REQUEST):
                errors.UPDATE_BASIC_INFO_REQUEST = action.message;
                break;
            case (at.UPDATE_ROLE_REQUEST):
                errors.UPDATE_ROLE_REQUEST = action.message;
                break;
            case (at.REMOVE_REQUEST):
                errors.REMOVE_REQUEST = action.message;
                break;
            default:
                break;
        }
    }

    return errors;
};


export default combineReducers({ providerUsers, providerUser, fetchStatus, fetchByIdStatus, fetchStatusNext, fetchStatusPrev, removeStatus, showRemovingModalStatus, createStatus, updateBasicInfoStatus, updateRoleStatus, errorStatus });
