import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../actions/types';

export function* updateBasicInfo(data) {
  try {
    yield call(api.updateUser, data);
    yield put({ type: at.UPDATE_BASIC_INFO_SUCCESS, data: data.data });
  } catch (error) {
    yield put({ type: at.UPDATE_BASIC_INFO_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data && error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Oops! something went wrong!',
      requestFailed: at.UPDATE_BASIC_INFO_REQUEST
    });
  }
}

export function* updateRole(data) {
  try {
    yield call(api.updateUserRole, data);
    yield put({ type: at.UPDATE_ROLE_SUCCESS, data: data.data });
  } catch (error) {
    yield put({ type: at.UPDATE_ROLE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data && error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Oops! something went wrong!',
      requestFailed: at.UPDATE_ROLE_REQUEST
    });
  }
}

export default { updateBasicInfo, updateRole };
