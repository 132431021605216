export default function(globalState) {
  const state = globalState.providers;

  const getProviderById = () => state.provider;
  const getFetchStatus = () => state.fetchStatus;
  const getSaveStatus = () => state.saveProviderStatus;
  const getErrors = () => state.errorStatus;
  const getUpdateAprovalsStatus = () => state.updateApprovalsStatus;
  const getToggleApprovalsInUseStatus = ()=> state.toggleApprovalsInUse;
  const getSaveLogoStatus = () => state.saveLogoStatus;
  const getSaveBannerStatus = () => state.saveBannerStatus;
  const getUpdateDiscountStatus = () => state.updateDiscountStatus;
  const getRemoveStripeAccountStatus = () => state.removeStripeAccountStatus;
  const getSaveCourseCompleteNotificationStatus = () => state.saveCourseCompleteNotificationStatus;
  const getSaveCourseStartedNotificationStatus = () => state.saveCourseStartedNotificationStatus;
  const getToggleCEBrokerIntegrationStatus = () => state.toggleCEBrokerIntegrationStatus;

  return {
    getProviderById,
    getFetchStatus,
    getSaveStatus,
    getErrors,
    getUpdateAprovalsStatus,
    getToggleApprovalsInUseStatus,
    getSaveLogoStatus,
    getSaveBannerStatus,
    getUpdateDiscountStatus,
    getRemoveStripeAccountStatus,
    getSaveCourseCompleteNotificationStatus,
    getSaveCourseStartedNotificationStatus,
    getToggleCEBrokerIntegrationStatus
  };
}
