export default function (globalState) {
    const state = globalState.CEBCourses;

    const getCourses = () => state.CEBCourses;
    const getFetchStatus = () => state.fetchStatus;
   
    return {
        getCourses,
        getFetchStatus
    };
}