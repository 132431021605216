import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import fetch from './fetch';
import remove from './remove';
import add from './add';

export default function* root() {
  yield takeLatest(at.FETCH_BY_PROVIDER_REQUEST, fetch.fetchByProvider);
  yield takeLatest(at.FETCH_BY_COURSE_REQUEST, fetch.fetchByCourse);
  yield takeLatest(at.ADD_COURSE_APPROVALS_REQUEST, add.addCourseApprovals);
  yield takeLatest(at.REMOVE_COURSE_APPROVALS_REQUEST, remove.removeApprovals);
  yield takeLatest(at.ADD_APPROVALS_REQUEST, add.addApprovals);
}

