import React from 'react';
import config from '../config';
import { entityInlineStyles } from '../components/CoolEditor/config';
import { EditorState, ContentState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';

const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createAction = (type, baseAction) => {
  return {
    [`${baseAction}`]: `${type}/${baseAction}`,
    [`${baseAction}_REQUEST`]: `${type}/${baseAction}_REQUEST`,
    [`${baseAction}_SUCCESS`]: `${type}/${baseAction}_SUCCESS`,
    [`${baseAction}_FAILURE`]: `${type}/${baseAction}_FAILURE`
  };
};

export const serveImage = (url) => {
  return config.API_URL + url;
};

export const daysInMonth = (month, year) => {
  return new Date(parseInt(year, 10), parseInt(month, 10), 0).getDate();
};

export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

export const isValidYoutubeUrl = (url) => {
  return YOUTUBEMATCH_URL.test(url);
};

export const isValidVimeoUrl = (url) => {
  return VIMEOMATCH_URL.test(url);
};

export const getYoutubeId = (url) => {
  return url.match(YOUTUBEMATCH_URL)[1];
};

export const getVimeoId = (url) => {
  return url.match(VIMEOMATCH_URL)[3];
};

export const getRelativeUrl = (absoluteUrl) => {
  const separator = absoluteUrl.includes('/Content/') ? '/Content/' : '/content/';
  const relativeUrl = absoluteUrl.split(separator).pop();
  return '/Content/' + relativeUrl;
};

export const nodeHasClass = (node, cssClass) => {
  const re = new RegExp(`\\b${cssClass}\\b`, 'g');
  if ((node.classList && node.classList.contains(cssClass)) || re.test(node.className)) {
    return true;
  }
  return false;
};

export const getStateFromHTML = (html) => {
  let editorState;

  if (html) {
    html = html.replace(/>\s*</gm, '><');

    let contentState = convertFromHTML({

      htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'sup') {
          return currentStyle.add('SUPERSCRIPT');
        }
        if (nodeName === 'sub') {
          return currentStyle.add('SUBSCRIPT');
        }

        return currentStyle;
      },

      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
          return createEntity(
            'LINK',
            'MUTABLE',
            { url: node.href }
          );
        }

        if (nodeName === 'img') {
          let imageUrl = node.src;
          if (node.src && node.src.includes('mytablemesa.com')) {
            imageUrl = config.API_URL + getRelativeUrl(imageUrl);
          }
          return createEntity(
            'IMAGE',
            'MUTABLE',
            { src: imageUrl, alignment: node.getAttribute('data-alignment'), width: node.getAttribute('data-width') }
          );
        }

        if (nodeName === 'audio') {
          let audioUrl = node.src;
          for (let child of node.childNodes) {
            if (child.nodeName === 'SOURCE') {
              audioUrl = child.src;
              break;
            }
          }
          if (audioUrl && audioUrl.includes('mytablemesa.com')) {
            audioUrl = config.API_URL + getRelativeUrl(audioUrl);
          }
          return createEntity(
            'AUDIO',
            'MUTABLE',
            { src: audioUrl }
          );
        }

        if (nodeName === 'video') {
          let src = "";
          let poster = "";
          if (node.src !== window.location.href) {
            src = node.src;
          }
          if (node.poster !== window.location.href) {
            poster = node.poster;
          }
          return createEntity(
            'VIDEO',
            'IMMUTABLE',
            { id: node.id, src: src, posterUrl: poster, jobId: node.getAttribute('jobId') }
          )
        }

        if (nodeName === 'iframe') {
          return createEntity(
            'draft-js-video-plugin-video',
            'IMMUTABLE',
            { src: node.src }
          );
        }
      },

      htmlToBlock: (nodeName, node) => {
        if (nodeName === 'figure' || nodeHasClass(node, 'medium-insert-audio')) {
          return 'atomic';
        }

        // Do not render buttons from embedded videos from the old site nor empty audio divs
        if (
          nodeName === 'div' &&
          (nodeHasClass(node, 'medium-insert-buttons') ||
            nodeHasClass(node, 'medium-insert-embeds-ovelay') ||
            nodeHasClass(node, 'medium-audio-selector'))
        ) {
          return false;
        }
      }
    })(html);

    let contentMap = contentState.getBlockMap().map((block) => {
      if (block.getType() === 'atomic') {
        return block.set('text', ' ');
      } else if (block.getText() === '\n') {
        return block.set('text', '');
      } else {
        return block;
      }
    });

    contentState = ContentState.createFromBlockArray(contentMap.toArray(), contentState.getEntityMap());

    editorState = EditorState.createWithContent(contentState);
  } else {
    editorState = EditorState.createEmpty();
  }

  return editorState;
};

export const getHTMLFromState = (editorState) => {
  let html = convertToHTML({

    styleToHTML: (style) => {
      if (style === 'SUPERSCRIPT') {
        return {
          start: '<sup>',
          end: '</sup>'
        };
      }
      if (style === 'SUBSCRIPT') {
        return {
          start: '<sub>',
          end: '</sub>'
        };
      }
    },

    entityToHTML: (entity, originalText) => {
      let styles = "";

      if (entity.type === 'LINK') {
        return `<a href="${entity.data.url}" target="_blank" rel="noopener noreferrer">${originalText}</a>`;
      }

      if (entity.type === 'IMAGE') {
        let params = ``;
        styles += entityInlineStyles.IMAGE.style;

        if (entity.data.width) {
          params += `data-width="${entity.data.width}"`;
          styles += `width: ${entity.data.width}%;`;
        }

        if (entity.data.alignment) {
          params += ` data-alignment="${entity.data.alignment}"`;
          switch (entity.data.alignment) {
            case 'left':
              styles += entityInlineStyles.IMAGE.alignment.left.style;
              break;
            case 'center':
              styles += entityInlineStyles.IMAGE.alignment.center.style;
              break;
            case 'right':
              styles += entityInlineStyles.IMAGE.alignment.right.style;
              break;
            default:
              break;
          }
        }

        const relativeUrl = entity.data.src ? entity.data.src.split(config.API_URL)[1] : '';
        return `<img src="${relativeUrl}" ${params} style="${styles}" />`;
      }

      if (entity.type === 'AUDIO') {
        return `<audio src="${entity.data.src}" controls />`;
      }

      if (entity.type === 'VIDEO') {
        if (entity.data.id) {
          return `<video width="100%" height="auto" controls preload="none" jobId="${entity.data.jobId}" poster="${entity.data.posterUrl}" type="mp4" id="${entity.data.id}" src="${entity.data.src}"></video>`;
        } else {
          return '';
        }
      }

      if (entity.type === 'draft-js-video-plugin-video') {
        let newUrl = entity.data.src;
        if (isValidYoutubeUrl(newUrl)) {
          let videoId = getYoutubeId(newUrl);
          newUrl = `https://www.youtube.com/embed/${videoId}`;
        } else if (isValidVimeoUrl(newUrl)) {
          let videoId = getVimeoId(newUrl);
          newUrl = `https://player.vimeo.com/video/${videoId}`;
        }
        return `<div style="left: 0px; width: 100%; height: 0px; position: relative; padding-bottom: 56.2493%;"><iframe src="${newUrl}" allowfullscreen frameborder="0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div></div>`;
      }

      return originalText;
    },

    blockToHTML: (block) => {
      if (block.type === 'atomic') {
        return {
          start: "<figure>",
          end: "</figure>",
          empty: ""
        };
      }
      if ((block.type === 'unstyled' || block.type === 'paragraph') && !block.text) {
        return <p><br /></p>;
      }
    },
  })(editorState);

  return html;
};

export const getElementPosition = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
};

export const smoothScrollTo = (elementTop) => {
  var i = 10;
  var int = setInterval(function () {
    window.scrollTo(0, i);
    i += 50;
    if (i >= elementTop) clearInterval(int);
  }, 20);
};

export const easingFunction = (t, b, c, d) => {
  var ts = (t /= d) * t;
  var tc = ts * t;
  return b + c * (tc + -3 * ts + 3 * t);
};

export const randomColor = (opacity) => {
  return 'rgba(' + randomColorFactor() + ',' + randomColorFactor() + ',' + randomColorFactor() + ',' + (opacity || '1') + ')';
};

export const randomColorFactor = () => {
  return Math.round(Math.random() * 255);
};
