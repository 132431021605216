import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/approvals';
import at from '../actions/types';

function* fetchByProvider(data) {
  try {
    const result = yield call(api.fetch, data);
    yield put({ type: at.FETCH_BY_PROVIDER_SUCCESS, approvals: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_BY_PROVIDER_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_BY_PROVIDER_REQUEST
    });
  }
}

function* fetchByCourse(data) {
  try {
    const result = yield call(api.fetchCourseApprovals, data);
    yield put({ type: at.FETCH_BY_COURSE_SUCCESS, approvals: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_BY_COURSE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_BY_COURSE_REQUEST
    });
  }
}

export default { fetchByProvider, fetchByCourse };