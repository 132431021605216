export default {
  FETCH_ACTIVITY_REQUEST: 'rosters/FETCH_ACTIVITY_REQUEST',
  FETCH_ACTIVITY_SUCCESS: 'rosters/FETCH_ACTIVITY_SUCCESS',
  FETCH_ACTIVITY_FAILURE: 'rosters/FETCH_ACTIVITY_FAILURE',
  FETCH_TRANSACTIONS_REQUEST: 'rosters/FETCH_TRANSACTIONS_REQUEST',
  FETCH_TRANSACTIONS_SUCCESS: 'rosters/FETCH_TRANSACTIONS_SUCCESS',
  FETCH_TRANSACTIONS_FAILURE: 'rosters/FETCH_TRANSACTIONS_FAILURE',
  FETCH_NEXT_REQUEST: 'rosters/FETCH_NEXT_REQUEST',
  FETCH_NEXT_SUCCESS: 'rosters/FETCH_NEXT_SUCCESS',
  FETCH_NEXT_FAILURE: 'rosters/FETCH_NEXT_FAILURE',
  REPORT_LICENSE_CEB_REQUEST: 'rosters/REPORT_LICENSE_CEB_REQUEST',
  REPORT_LICENSE_CEB_SUCCESS: 'rosters/REPORT_LICENSE_CEB_SUCCESS',
  REPORT_LICENSE_CEB_FAILURE: 'rosters/REPORT_LICENSE_CEB_FAILURE',
  UPDATE_LICENSE_REQUEST: 'rosters/UPDATE_LICENSE_REQUEST',
  UPDATE_LICENSE_SUCCESS: 'rosters/UPDATE_LICENSE_SUCCESS',
  UPDATE_LICENSE_FAILURE: 'rosters/UPDATE_LICENSE_FAILURE',
  TOGGLE_LICENSE_EDITING: 'rosters/TOGGLE_LICENSE_EDITING',
  REQUEST_FAILURE: 'rosters/REQUEST_FAILURE',
  FETCH_TRANSACTION_DETAILS_REQUEST: 'rosters/FETCH_TRANSACTION_DETAILS_REQUEST',
  FETCH_TRANSACTION_DETAILS_SUCCESS: 'rosters/FETCH_TRANSACTION_DETAILS_SUCCESS',
  FETCH_TRANSACTION_DETAILS_FAILURE: 'rosters/FETCH_TRANSACTION_DETAILS_FAILURE'
};
