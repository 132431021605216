import at from './types';

export const fetchTransactions = function (data) {
    return { type: at.FETCH_TRANSACTIONS_REQUEST, data };
};

export const fetchActivity = function (data) {
    return { type: at.FETCH_ACTIVITY_REQUEST, data };
};

export const fetchNext = function (url) {
    return { type: at.FETCH_NEXT_REQUEST, url };
};

export const reportLicenseToCEBroker = function (data) {
    return { type: at.REPORT_LICENSE_CEB_REQUEST, data };
};

export const updateLicenseNumber = function (data) {
    return { type: at.UPDATE_LICENSE_REQUEST, data };
};

export const toggleLicenseEditing = function (isEditing) {
    return { type: at.TOGGLE_LICENSE_EDITING, isEditing };
};

export const fetchTransactionDetails = function (data) {
    return { type: at.FETCH_TRANSACTION_DETAILS_REQUEST, data };
};
