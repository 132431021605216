export default {
  FETCH_REQUEST: 'loggedInUser/FETCH_REQUEST',
  FETCH_SUCCESS: 'loggedInUser/FETCH_SUCCESS',
  FETCH_FAILURE: 'loggedInUser/FETCH_FAILURE',

  UPDATE_REQUEST: 'loggedInUser/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'loggedInUser/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'loggedInUser/UPDATE_FAILURE',

  SET_USER_PROVIDER_LOGO: 'loggedInUser/SET_USER_PROVIDER_LOGO',
  REFRESH_USER_PROVIDER_LOGO: 'loggedInUser/REFRESH_USER_PROVIDER_LOGO',

  CONVERT_USER_ASSOCIATIONS_REQUEST:'loggedInUser/CONVERT_USER_ASSOCIATIONS_REQUEST',
  CONVERT_USER_ASSOCIATIONS_SUCCESS:'loggedInUser/CONVERT_USER_ASSOCIATIONS_SUCCESS',
  CONVERT_USER_ASSOCIATIONS_FAILURE:'loggedInUser/CONVERT_USER_ASSOCIATIONS_FAILURE',

  CLEAR_CONVERT_USER_ASSOCIATIONS_STATUS: 'loggedInUser/CLEAR_CONVERT_USER_ASSOCIATIONS_STATUS',

  REQUEST_FAILURE: 'loggedInUser/REQUEST_FAILURE'
};
