import axios from 'axios';
import config from '../config';

export const fetchQuizzes = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/shorttests',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const updateQuizzesIndex = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttests',
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const createQuiz = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttests',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const updateQuiz = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttests?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const fetchQuizQuestions = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestquestions',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const addQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestquestions',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestquestions?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteQuestion = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestquestions',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};

export const addAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestanswers',
        method: 'POST',
        data: data,
        withCredentials: true
    });
};

export const saveAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestanswers?id=' + data.id,
        method: 'PATCH',
        data: data,
        withCredentials: true
    });
};

export const deleteAnswer = (data) => {
    return axios.request({
        url: config.API_URL + 'api/shorttestanswers',
        method: 'DELETE',
        params: data,
        withCredentials: true
    });
};
