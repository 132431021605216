import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providerUsers';
import at from '../actions/types';

export function* fetchByProvider(url) {
  try {
    const result = yield call(api.getByProviderId, url);
    yield put({ type: at.FETCH_BY_PROVIDER_SUCCESS, providerUsers: result.data});
  } catch (error) {
    yield put({ type: at.FETCH_BY_PROVIDER_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data: url,
        message: error.response.data.message || error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_BY_PROVIDER_REQUEST
    });
  }
}

export function* fetchById(data) {
  try {
    const result = yield call(api.getById, data);
    yield put({ type: at.FETCH_BY_ID_SUCCESS, providerUser: result.data});
  } catch (error) {
    yield put({ type: at.FETCH_BY_ID_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_BY_ID_REQUEST
    });
  }
}

export default { fetchByProvider, fetchById };

