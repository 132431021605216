export default function (globalState) {
    const state = globalState.states;

    const getStates = () => state.states;
    const getFetchStatus = () => state.fetchStatus;
    const getErrors = () => state.errorStatus;

    return {
        getStates,
        getFetchStatus,
        getErrors
    };
}
