import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = {};

export const courseCoupon = (state = defaultState, action) => {
    switch (action.type) {
        case at.SAVE_COURSE_COUPON_REQUEST:
        case at.SAVE_COURSE_COUPON_SUCCESS: {
            return defaultState;
        }
        case at.SAVE_COURSE_COUPON_FAILURE: {
            return defaultState;
        }
        default:
            return state;
    }
};

export const courseCouponStatus = (state = 'notSaved', action) => {
    switch (action.type) {
        case at.SAVE_COURSE_COUPON_REQUEST: {
            return 'saving';
        }
        case at.SAVE_COURSE_COUPON_SUCCESS: {
            return 'saved';
        }
        case at.SAVE_COURSE_COUPON_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};


export const toggleCourseCouponStatus = (state = false, action) => {
    switch (action.type) {
        case at.TOGGLE_SAVE_COURSE_COUPON_STATUS: {
            const { saved } = action;
            return saved;
        }
        default:
            return state;
    }
};

export const errorStatus = (state = {}, action, request) => {
    switch (action.type) {
        case at.REQUEST_FAILURE: {
            return action;
        }
        default:
            return state;
    }
};

export default combineReducers({ courseCoupon, courseCouponStatus, toggleCourseCouponStatus, errorStatus });

