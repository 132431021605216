export default function (globalState) {
    const state = globalState.courseCoupon;

    const getCourseCouponStatus = () => state.courseCouponStatus;
    const getToggleSaveCourseCouponStatus = () => state.toggleCourseCouponStatus;
    const getErrors = () => state.errorStatus;

    return {
        getCourseCouponStatus,
        getToggleSaveCourseCouponStatus,
        getErrors
    };
}
