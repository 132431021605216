import axios from 'axios';
import config from '../config';

export const fetchAllByCourseId = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/students/getallbycourse',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchAll = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/students',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchById = (data) => {
    return axios.request({
        url: config.API_URL + 'api/students',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchByTestAndLicensee = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/students',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};
