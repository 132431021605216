export const entityInlineStyles = {
    IMAGE: {
        style: "position: relative;cursor: default;",
        alignment: {
            left: {
                style: "float: left;"
            },
            center: {
                style: "margin-left: auto;margin-right: auto;display: block;"
            },
            right: {
                style: "float: right;"
            }
        }
    }
}