import { call, put, all } from 'redux-saga/effects';
import * as apiCourses from '../../../api/courses';
import * as apiCourseContentPages from '../../../api/courseContentPages';
import * as apiTest from '../../../api/tests.js';
import * as apiSurveys from '../../../api/surveys.js';
import * as apiQuizzes from '../../../api/quizzes.js';

import at from '../actions/types';

function* fetchCoursePages(data) {
    try {
        const [contentPages, tests, surveys, quizzes] = yield all(
            [
                call(apiCourseContentPages.fetchCourseContentPages, data.dataCourseContentPage),
                call(apiTest.fetchTests, data.testData),
                call(apiSurveys.fetchSurveys, data.surveyData),
                call(apiQuizzes.fetchQuizzes, data.shortTestData)
            ]
        );
        yield put({ type: at.FETCH_COURSE_PAGES_SUCCESS, pages: [contentPages, tests, surveys, quizzes] });
    } catch (error) {
        yield put({ type: at.FETCH_COURSE_PAGES_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.FETCH_COURSE_PAGES_REQUEST
        });
    }
}

function* updateCourseContent(data) {
    try {
        yield put({ type: at.UPDATE_COURSE_CONTENT_SUCCESS, content: data });
    } catch (error) {
        yield put({ type: at.UPDATE_COURSE_CONTENT_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_COURSE_CONTENT_REQUEST
        });
    }
}

function* updatePagesIndexes(data) {
    try {
        yield all(
            [
                call(apiCourseContentPages.updateCourseContentPageIndex, data.dataCourseContentPage),
                call(apiTest.updateTestsIndex, data.testsData),
                call(apiSurveys.updateSurveysIndex, data.surveyData),
                call(apiQuizzes.updateQuizzesIndex, data.shortTestData)
            ]
        );
        yield put({ type: at.UPDATE_PAGE_INDEXES_SUCCESS });
    } catch (error) {
        yield put({ type: at.UPDATE_PAGE_INDEXES_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_PAGE_INDEXES_REQUEST
        });
    }
}

function* deleteContent({ data }) {
    try {
        yield call(apiCourses.deleteContent, data);
        yield put({ type: at.DELETE_CONTENT_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.DELETE_CONTENT_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.DELETE_CONTENT_REQUEST
        });
    }
}

export default { fetchCoursePages, updateCourseContent, updatePagesIndexes, deleteContent }
