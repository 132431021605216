import {  put } from 'redux-saga/effects';
import at from '../actions/types';

function* addCourseApprovals(data) {
    try {
      yield put({ type: at.ADD_COURSE_APPROVALS_SUCCESS, courseApprovals: data.data });
    } catch (error) {
      yield put({ type: at.ADD_COURSE_APPROVALS_FAILURE });
      yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.ADD_COURSE_APPROVALS_REQUEST
      });
    }
  }

  function* addApprovals(data) {
    try {
      yield put({ type: at.ADD_APPROVALS_SUCCESS, approval: data });
    } catch (error) {
      yield put({ type: at.ADD_APPROVALS_FAILURE });
      yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.ADD_APPROVALS_REQUEST
      });
    }
  }
  
  

export default { addCourseApprovals, addApprovals };