import { call, put } from 'redux-saga/effects';
import * as apiSurveys from '../../../api/surveys.js';

import at from '../actions/types';

function* createSurvey({ data }) {
    try {
        const result = yield call(apiSurveys.createSurvey, data);
        yield put({ type: at.CREATE_SURVEY_SUCCESS, result });
    } catch (error) {
        yield put({ type: at.CREATE_SURVEY_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.CREATE_SURVEY_REQUEST
        });
    }
}

function* updateSurvey({ data }) {
    try {
        yield call(apiSurveys.updateSurvey, data);
        yield put({ type: at.UPDATE_SURVEY_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_SURVEY_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.response.data.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_SURVEY_REQUEST
        });
    }
}

export default { createSurvey, updateSurvey };
