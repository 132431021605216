export default function(globalState) {
  const state = globalState.loggedInUser;

  const getUser = () => state.user;
  const getFetchStatus = () => state.fetchStatus;
  const getUpdateStatus = () => state.updateStatus;
  const getConvertUserToAssociationsStatus = () => state.convertUserToAssociationsStatus
  const getErrors = () => state.errorStatus;

  return {
    getUser,
    getFetchStatus,
    getUpdateStatus,
    getConvertUserToAssociationsStatus,
    getErrors
  };
}
