import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/users';
import at from '../actions/types';

export function* updateBasicInfo({ data }) {
  try {
    yield call(api.updateLoggedInUser, { data: data });
    yield put({ type: at.UPDATE_SUCCESS, user: data });
  } catch (error) {
    yield put({ type: at.UPDATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.UPDATE_REQUEST
    });
  }
}

export function* convertUserToAssociations({ data }) {
  try {
      yield call(api.convertToAssociations, { data: data });
      yield put({ type: at.CONVERT_USER_ASSOCIATIONS_SUCCESS });
  } catch (error) {
      yield put({ type: at.CONVERT_USER_ASSOCIATIONS_FAILURE });
      yield put({
          type: at.REQUEST_FAILURE,
          data,
          message: error.message || 'Oops! something went wrong!',
          requestFailed: at.CONVERT_USER_ASSOCIATIONS_REQUEST
      });
  }
}

export default { updateBasicInfo, convertUserToAssociations };