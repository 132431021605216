import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';

export default function* updateDiscount({ data }) {
  try {
    yield call(api.saveDiscountProgram, { data: data });
    yield put({ type: at.UPDATE_DISCOUNT_SUCCESS, provider: data });
  } catch (error) {
    yield put({ type: at.UPDATE_DISCOUNT_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.UPDATE_DISCOUNT_REQUEST
    });
  }
}
