export default {
    FETCH_BY_PROVIDER_REQUEST: 'providerusers/FETCH_BY_PROVIDER_REQUEST',
    FETCH_BY_PROVIDER_SUCCESS: 'providerusers/FETCH_BY_PROVIDER_SUCCESS',
    FETCH_BY_PROVIDER_FAILURE: 'providerusers/FETCH_BY_PROVIDER_FAILURE',

    FETCH_BY_ID_REQUEST:'providerusers/FETCH_BY_ID_REQUEST',
    FETCH_BY_ID_SUCCESS: 'providerusers/FETCH_BY_ID_SUCCESS',
    FETCH_BY_ID_FAILURE: 'providerusers/FETCH_BY_ID_FAILURE',

    CREATE_REQUEST: 'providerusers/CREATE_REQUEST',
    CREATE_SUCCESS: 'providerusers/CREATE_SUCCESS',
    CREATE_FAILURE: 'providerusers/CREATE_FAILURE',

    CLEAR_CREATE_STATUS: 'providerusers/CLEAR_CREATE_STATUS',

    UPDATE_BASIC_INFO_REQUEST: 'providerusers/UPDATE_BASIC_INFO_REQUEST',
    UPDATE_BASIC_INFO_SUCCESS: 'providerusers/UPDATE_BASIC_INFO_SUCCESS',
    UPDATE_BASIC_INFO_FAILURE: 'providerusers/UPDATE_BASIC_INFO_FAILURE',

    CLEAR_UPDATE_INFO_STATUS: 'providerusers/CLEAR_UPDATE_INFO_STATUS',

    UPDATE_ROLE_REQUEST: 'providerusers/UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'providerusers/UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAILURE: 'providerusers/UPDATE_ROLE_FAILURE',

    CLEAR_UPDATE_ROLE_STATUS: 'providerusers/CLEAR_UPDATE_ROLE_STATUS',

    REMOVE_REQUEST: 'providerusers/REMOVE_REQUEST',
    REMOVE_SUCCESS: 'providerusers/REMOVE_SUCCESS',
    REMOVE_FAILURE: 'providerusers/REMOVE_FAILURE',
    CLEAR_REMOVE_STATUS: 'providerusers/CLEAR_REMOVE_STATUS',
    TOGGLE_REMOVE_MODAL: 'providerusers/TOGGLE_REMOVE_MODAL',

    REQUEST_FAILURE: 'providerusers/REQUEST_FAILURE'
}
