import at from './types';

export const fetch = function (data) {
    return { type: at.FETCH_REQUEST, data };
};

export const createBankAccount = function (data) {
    return { type: at.CREATE_REQUEST, data };
};

export const clearCreateStatus = function () {
    return { type: at.CLEAR_CREATE_STATUS };
};

export const updateBankAccount = function (data) {
    return { type: at.UPDATE_REQUEST, data };
};
