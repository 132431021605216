import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providers';
import at from '../actions/types';

export default function* saveBanner(data) {
    try {
        const bannerImageUrl = data.data.BannerImageUrl;
        yield call(api.saveProviderBanner, data);
        yield put({ type: at.SAVE_BANNER_SUCCESS, bannerImageUrl });
    } catch (error) {
        yield put({ type: at.SAVE_BANNER_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.SAVE_BANNER_REQUEST
        });
    }
}
