import { combineReducers } from 'redux';
import at from '../actions/types';

const defaultState = [];

export const associationsMembers = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_BY_ASSOCIATION_SUCCESS: {
            let { associationsMembers } = action;
            return associationsMembers ? associationsMembers.items : [];
        }
        default:
            return state;
    }
};

export const associationsMember = (state = defaultState, action) => {
    switch (action.type) {
        case at.FETCH_MEMBER_BY_ID_SUCCESS: {
            let { associationsMember } = action;
            return associationsMember;
        }
        default:
            return state;
    }
};

export const invitesResults = (state = defaultState, action) => {
    switch (action.type) {
        case at.POST_MEMBER_INVITES_SUCCESS: {
            let { results } = action;
            return results;
        }
        default:
            return state;
    }
};

export const fetchStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_BY_ASSOCIATION_REQUEST: {
            return 'loading';
        }
        case at.FETCH_BY_ASSOCIATION_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_BY_ASSOCIATION_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchAssociationsMemberStatus = (state = 'notLoaded', action) => {
    switch (action.type) {
        case at.FETCH_MEMBER_BY_ID_REQUEST: {
            return 'loading';
        }
        case at.FETCH_MEMBER_BY_ID_SUCCESS: {
            return 'loaded';
        }
        case at.FETCH_MEMBER_BY_ID_FAILURE: {
            return 'failed';
        }
        default:
            return state;
    }
};

export const fetchStatusNext = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_BY_ASSOCIATION_SUCCESS: {
            const { associationsMembers: { next } } = action;
            return next ? true : false;
        }
        default:
            return state;
    }
};

export const fetchStatusPrev = (state = false, action) => {
    switch (action.type) {
        case at.FETCH_BY_ASSOCIATION_SUCCESS: {
            const { associationsMembers: { previous } } = action;
            return previous ? true : false;
        }
        default:
            return state;
    }
};

export const removeStatus = (state = 'notRemoved', action) => {
    switch (action.type) {
        case at.REMOVE_REQUEST: {
            return 'removing';
        }
        case at.REMOVE_SUCCESS: {
            return 'removed';
        }
        case at.REMOVE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_REMOVE_STATUS: {
            return 'notRemoved';
        }
        default:
            return state;
    }
};

export const showRemovingModalStatus = (state = false, action) => {
    switch (action.type) {
        case at.TOGGLE_REMOVE_MODAL: {
            const { showModal } = action;
            return showModal;
        }
        default:
            return state;
    }
};

export const postStatus = (state = 'notPosted', action) => {
    switch (action.type) {
        case at.POST_MEMBER_INVITES_REQUEST: {
            return 'posting';
        }
        case at.POST_MEMBER_INVITES_SUCCESS: {
            return 'posted';
        }
        case at.POST_MEMBER_INVITES_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_POST_MEMBER_STATUS: {
            return 'notPosted';
        }
        default:
            return state;
    }
};

export const cancelStatus = (state = 'notCancelled', action) => {
    switch (action.type) {
        case at.CANCEL_INVITE_REQUEST: {
            return 'cancelling';
        }
        case at.CANCEL_INVITE_SUCCESS: {
            return 'cancelled';
        }
        case at.CANCEL_INVITE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_CANCEL_STATUS: {
            return 'notCancelled';
        }
        default:
            return state;
    }
};

export const resendStatus = (state = 'notSent', action) => {
    switch (action.type) {
        case at.RESEND_INVITE_REQUEST: {
            return 'sending';
        }
        case at.RESEND_INVITE_SUCCESS: {
            return 'sent';
        }
        case at.RESEND_INVITE_FAILURE: {
            return 'failed';
        }
        case at.CLEAR_RESEND_STATUS: {
            return 'notSent';
        }
        default:
            return state;
    }
};

export const errorStatus = (state = {}, action) => {
    let errors = {};

    if (action.type === at.REQUEST_FAILURE) {
        switch (action.requestFailed) {
            case (at.FETCH_BY_ASSOCIATION_REQUEST):
                errors.FETCH_BY_ASSOCIATION_REQUEST = action.message;
                break;
            case (at.CANCEL_INVITE_REQUEST):
                errors.CANCEL_INVITE_REQUEST = action.message;
                break;
            case (at.RESEND_INVITE_REQUEST):
                errors.RESEND_INVITE_REQUEST = action.message;
                break;
            default:
                break;
        }
    }

    return errors;
};


export default combineReducers({ associationsMembers, associationsMember, invitesResults, fetchStatus, fetchStatusNext, fetchStatusPrev, postStatus, cancelStatus, resendStatus, removeStatus, showRemovingModalStatus, fetchAssociationsMemberStatus, errorStatus });
