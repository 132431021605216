import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/providerUsers';
import at from '../actions/types';

export default function* create(data) {
  try {
    const result = yield call(api.createProviderUser, data);
    yield put({ type: at.CREATE_SUCCESS, results: result.data});
  } catch (error) {
    yield put({ type: at.CREATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data && error.response.data.message ? error.response.data.message : 'Oops! something went wrong!',
      requestFailed: at.CREATE_REQUEST
    });
  }
}
