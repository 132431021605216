import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/stripeAccount';
import at from '../actions/types';
import * as actions from '../actions';

export default function* update(data) {
  try {
    const response = yield call(api.updateAccount, data);
    yield put({ type: at.UPDATE_SUCCESS, response: response.data });
    //Get updated account
    yield put(actions.fetch({ stripeAccountId: response.data.id }));
  } catch (error) {
    yield put({ type: at.UPDATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data && error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Oops! something went wrong!',
      requestFailed: at.UPDATE_REQUEST
    });
  }
}
