import at from './types';

export const fetchByProvider = function (url) {
    return { type: at.FETCH_BY_PROVIDER_REQUEST, url };
};

export const fetchById = function (data) {
    return { type: at.FETCH_BY_ID_REQUEST, data };
};

export const createProviderUser = function (data) {
    return { type: at.CREATE_REQUEST, data };
};

export const clearCreateStatus = function () {
    return { type: at.CLEAR_CREATE_STATUS };
};

export const updateProviderUserInfo = function (data) {
    return { type: at.UPDATE_BASIC_INFO_REQUEST, data };
};

export const clearUpdateBasicInfoStatus = function () {
    return { type: at.CLEAR_UPDATE_INFO_STATUS };
};

export const updateProviderUserRole = function (data) {
    return { type: at.UPDATE_ROLE_REQUEST, data };
};

export const clearUpdateRoleStatus = function () {
    return { type: at.CLEAR_UPDATE_ROLE_STATUS };
};

export const removeProviderUser = function (data) {
    return { type: at.REMOVE_REQUEST, data };
};

export const clearRemoveStatus = function () {
    return { type: at.CLEAR_REMOVE_STATUS };
};

export const toggleRemoveModal = function (showModal) {
    return { type: at.TOGGLE_REMOVE_MODAL, showModal };
};
