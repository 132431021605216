import axios from 'axios';
import config from '../config';

export const getByProviderId = ({ url }) => {
    return axios.request({
        url: config.API_URL + url,
        method: 'GET',
        withCredentials: true
    });
};

export const getById = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providerusers',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const removeProviderUser = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providerusers?id=' + data.id,
        method: 'DELETE',
        withCredentials: true
    });
};

export const createProviderUser = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/providerusers',
        method: 'POST',
        withCredentials: true,
        data: data
    });
};