import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/associationsmembers';
import at from '../actions/types';

export function* postMemberInvites(data) {
  try {
    const result = yield call(api.postMemberInvites, data);
    yield put({ type: at.POST_MEMBER_INVITES_SUCCESS, results: result.data.invitesResults});
  } catch (error) {
    yield put({ type: at.POST_MEMBER_INVITES_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.POST_MEMBER_INVITES_REQUEST
    });
  }
}

export function* cancelInvite(data) {
  try {
    yield call(api.cancelInvite, data);
    yield put({ type: at.CANCEL_INVITE_SUCCESS });
  } catch (error) {
    yield put({ type: at.CANCEL_INVITE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data.message || error.message || 'Oops! something went wrong!',
      requestFailed: at.CANCEL_INVITE_REQUEST
    });
  }
}

export function* resendInvite(data) {
  try {
    yield call(api.resendInvite, data);
    yield put({ type: at.RESEND_INVITE_SUCCESS });
  } catch (error) {
    yield put({ type: at.RESEND_INVITE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.response.data.message || error.message || 'Oops! something went wrong!',
      requestFailed: at.RESEND_INVITE_REQUEST
    });
  }
}

export default { postMemberInvites, resendInvite, cancelInvite };
