import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/courses';
import at from '../actions/types';

function* updateFeatureCourse({ data }) {
    try {
        yield call(api.updateFeatureCourse, { data });
        yield put({ type: at.UPDATE_FEATURE_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_FEATURE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_FEATURE_REQUEST
        });
    }
}

function* updatePublishCourse({ data }) {
    try {
        yield call(api.updatePublishCourse, { data });
        yield put({ type: at.UPDATE_PUBLISH_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_PUBLISH_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            modelState: error.response.data && error.response.data.modelState ? error.response.data.modelState : {},
            message: error.response.data && error.response.data.modelState ? "You need to complete the course first. Edit the course to add the missing parts." : 'Oops! something went wrong!',
            requestFailed: at.UPDATE_PUBLISH_REQUEST
        });
    }
}

function* updatePublishCourseBuilder({ data }) {
    try {
        yield call(api.updatePublishCourse, { data });
        yield put({ type: at.UPDATE_PUBLISH_COURSE_BUILDER_SUCCESS, data });
    } catch (error) {
        yield put({
            type: at.UPDATE_PUBLISH_COURSE_BUILDER_FAILURE,
            data,
            modelState: error.response.data && error.response.data.modelState ? error.response.data.modelState : {}
        });
    }
}

function* updateCebCode({ data }) {
    try {
        yield call(api.updateCEBCourseCode, { data });
        yield put({ type: at.UPDATE_CEBCODE_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_CEBCODE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_CEBCODE_REQUEST
        });
    }
}

function* createCourse({ data }) {
    try {
        const course = yield call(api.createCourse, { data });
        yield put({ type: at.CREATE_COURSE_SUCCESS, course: course.data });
    } catch (error) {
        yield put({ type: at.CREATE_COURSE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.CREATE_COURSE_REQUEST
        });
    }
}

function* cleanCebCodes({ data }) {
    try {
        yield call(api.disableCEBrokerIntegration, { data });
        yield put({ type: at.CLEAN_CEBCODE_COURSES_SUCCESS });
    } catch (error) {
        yield put({ type: at.CLEAN_CEBCODE_COURSES_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.CLEAN_CEBCODE_COURSES_REQUEST
        });
    }
}

function* fetchCourse(data) {
    try {
        const result = yield call(api.fetchCourses, data);
        yield put({ type: at.FETCH_COURSE_SUCCESS, course: result.data });
    } catch (error) {
        yield put({ type: at.FETCH_COURSE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.FETCH_COURSE_REQUEST
        });
    }
}

function* updateCourse({ data }) {
    try {
        yield call(api.updateCourse, { data });
        yield put({ type: at.UPDATE_SUCCESS, data: data });
    } catch (error) {
        yield put({ type: at.UPDATE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_REQUEST
        });
    }
}

function* updateCebCodeIndividualCourse({ data }) {
    try {
        yield call(api.updateCEBCourseCode, { data });
        yield put({ type: at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_SUCCESS, data });
    } catch (error) {
        yield put({ type: at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: error.message || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_CEBCODE_INDIVIDUAL_COURSE_REQUEST
        });
    }
}

export default { updateFeatureCourse, updatePublishCourse, updatePublishCourseBuilder, createCourse, updateCebCode, cleanCebCodes, fetchCourse, updateCourse, updateCebCodeIndividualCourse }