import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/courses';
import at from '../actions/types';

export default function* fetch(data) {
  try {
    const result = yield call(api.fetchAllCoursesByProvider, data);
    yield put({ type: at.FETCH_SUCCESS, courses: result.data });
  } catch (error) {
    yield put({ type: at.FETCH_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: error.message || 'Oops! something went wrong!',
      requestFailed: at.FETCH_REQUEST
    });
  }
}
