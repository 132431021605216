import axios from 'axios';
import config from '../config';

export const create = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/approvals/',
        method: 'POST',
        params: data,
        withCredentials: true
    });
};

export const updateApprovalsList = (data) => {
    return axios.request({
        url: config.API_URL + 'api/approvals/updateapprovalslist',
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Data-Type':'json'
        },
        data: JSON.stringify(data),
        withCredentials: true
    }).catch((error)=>{
        return error.response
    });
};

export const fetch = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/approvals/',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const fetchCourseApprovals = ({ data }) => {
    return axios.request({
        url: config.API_URL + 'api/courseApprovals/all?expand=approval',
        method: 'GET',
        params: data,
        withCredentials: true
    });
};

export const addCourseApprovals = ({ data }) => {
    let { courseId, approvals } = data;

    return axios.request({
        url: config.API_URL + 'api/courseApprovals?courseId=' + courseId,
        method: 'POST',
        data: approvals,
        withCredentials: true
    });
};

export const addApprovals = (data) => {
    return axios.request({
        url: config.API_URL + 'api/approvals?courseId=' + data.courseId,
        method: 'POST',
        data: data.approvals,
        withCredentials: true
    });
};

export const removeCourseApprovals = (data) => {
    return axios.request({
        url: config.API_URL + 'api/courseApprovals?courseId=' + data.courseId,
        method: 'DELETE',
        withCredentials: true
    });
};



