import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/associationsmembers';
import at from '../actions/types';

export function* fetchByAssociation(data) {
  try {
    const result = yield call(api.getByAssociationId, data);
    yield put({ type: at.FETCH_BY_ASSOCIATION_SUCCESS, associationsMembers: result.data});
  } catch (error) {
    yield put({ type: at.FETCH_BY_ASSOCIATION_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.response.data.message || error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_BY_ASSOCIATION_REQUEST
    });
  }
}

export function* fetchById(data) {
  try {
    const result = yield call(api.getById, data);
    yield put({ type: at.FETCH_MEMBER_BY_ID_SUCCESS, associationsMember: result.data});
  } catch (error) {
    yield put({ type: at.FETCH_MEMBER_BY_ID_FAILURE });
    yield put({
        type: at.REQUEST_FAILURE,
        data,
        message: error.message || 'Oops! something went wrong!',
        requestFailed: at.FETCH_MEMBER_BY_ID_REQUEST
    });
  }
}

export default { fetchByAssociation, fetchById };

