import { call, put } from 'redux-saga/effects';
import * as api from '../../../api/approvals';
import at from '../actions/types';

export default function* updateApprovals({ data }) {
    const result = yield call(api.updateApprovalsList, data);
    if (result.status === 200) {
        const params = { result: result, data: data };
        yield put({ type: at.UPDATE_APPROVALS_SUCCESS, ...params});
    } else if (result.status === 400 && result.data.modelState.error[0] === 'There are approvals in use') {
        yield put({ type: at.TOGGLE_APPROVALS_IN_USE });
        yield put({type: at.CLEAR_APPROVALS_STATUS});
    } else {
        yield put({ type: at.UPDATE_APPROVALS_FAILURE });
        yield put({
            type: at.REQUEST_FAILURE,
            data,
            message: result.data.exceptionMessage || 'Oops! something went wrong!',
            requestFailed: at.UPDATE_APPROVALS_REQUEST,
        });
    }
}
